.wwu-main-heading {
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 5px;
}

.wwu-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.job-block {
  border: 1px solid black;
  border-radius: 10px;
  width: 70vw;
  /* margin: 30px 40px; */
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 20px 30px;
  text-align: left;
}

.job-title {
  padding-bottom: 20px;
}

.job-responsibilities {
  font-weight: 700;
}

.job-qualifications {
  font-weight: 700;
} 

.job-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.job-button {
  padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
	margin-top: 10px;
	margin-bottom: 10px;
}

@media (max-width: 768px){
  .job-block {
    width: 90vw;
    margin-bottom: 40px;
    padding: 20px 20px;
    text-align: left;
  }
}