.ylpa-overview-container {
	margin-left: 12px;
	margin-right: 12px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-size: 20px;
	/* font-weight: 600; */
	background-color: rgb(240, 240, 240);
	margin-bottom: 0px;
}

.ylpa-overview-text-1 {
  font-weight: 900;
}

.ylpa-overview-text-2 {
  font-weight: 500;
}

.ylp_img {
	width: 100vw;
}

.ylp_img img {
	width: 100%;
	height: auto;
}

.ylp-registeration-heading {
  font-family: anton;
	padding: 0 20px 20px 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 2.5rem;
  font-style: italic;
}

.ylp-registeration-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  gap: 30px;
}

.ylp-registeration-text {
  font-weight: 600;
  color: rgb(0, 120, 225);
  font-size: 1.5rem;
}

.ylp-registeration-btn {
  padding: 5px;
	padding-right: 25px;
	padding-left: 25px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
  font-size: 1.2rem;
}

.ylp-registeration-note {
	padding: 40px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.ylp-registeration-note p {
	text-align: left;
	font-size: 18px;
  font-weight: 600;
}

.ylp-discount-container {
	display: flex;
	max-width: 100vw;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #0076B8;
}

.ylp-discount-heading {
	color: #fff;
	font-size: 2rem;
	font-weight: 900;
}

.ylp-discount-heading_2 {
	color: #000000;
	font-size: 2rem;
	font-weight: 900;
}

.ylp-discount-p {
	color: #fff;
	font-size: 1.2rem;
	font-weight: 500;
	padding-left: 30px;
	padding-right: 30px;
}

.stat_banner_ylp {
	align-items: center;
	padding: 40px 0 40px 0;
}
.stat_banner_ylp img {
	width: 100%;
	height: auto;
}




@media (max-width: 768px) {
  .ylpa-overview-container {
		margin-left: 2px;
		margin-right: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 15px;
		text-align: justify;
		margin-bottom: 0px;
	}
  .ylp-registeration-text {
    padding: 0 20px 0 20px;
  }
	.stat_banner_ylp img {
		width: 100%;
		content: url('./images/ylp/YLP\ mob\ banner.webp');
	}
}

/* Ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.stat_banner_ylp img {
		width: 100%;
		content: url('./images/ylp/YLP\ mob\ banner.webp');
	}
}