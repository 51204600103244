.Winners-2024-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 30px;
}

.winner-phone {
  display: none;
}

.winner-2024-heading {
  color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
}

.winner-2024-heading1 {
  color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 56px;
}

.winner-2024-heading2 {
  color: black;
  text-align: left;
  padding-left: 300px;
	font-family: anton;
  padding-top: 40px;
	font-weight: 400;
	font-size: 44px;
}

.winner-2024-heading3 {
  color: black;
  text-align: left;
  padding-left: 300px;
	font-family: anton;
  padding-top: 20px;
  padding-bottom: 20px;
	font-weight: 400;
	font-size: 44px;
}

.yt_video_2024_winner {
	aspect-ratio: 16/9;
	width: 900px;
}

.images-2024 {
  width: 100vw;
  overflow: hidden;
}

.img-2024 {
  width: 70%;
}

@media (max-width: 768px) {
  .Winners-2024-hero{
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10px;
  }

  .winner-phone{
    display: block;
  }

  .winner-2024-heading1 {
    font-size: 34px;
  }

  .winner-2024-heading2 {
    padding-left: 25px;
    font-size: 24px;
    padding-top: 10px;
  }

  .winner-2024-heading3 {
    padding-left: 25px;
    font-size: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .winner-pc {
    display: none;
  }

  .winner-2024-heading{
    font-size: 30px;
  }

  .yt_video_2024_winner {
    aspect-ratio: 16/9;
    width: 300px !important;
  }

  .img-2024 {
    width: 100%;
  }
}