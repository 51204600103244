.head-am2 {
	font-size: 45px;
	text-align: center;
	font-family: "anton", sans-serif;
	color: rgb(43, 143, 66);
}
.part_d1 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 30px;
	padding-right: 30px;
	align-items: center;
	padding-top: 15px;
}
.part_d1 p {
	max-width: 99%;
	overflow: hidden;
	font-size: 33px;
	color: rgb(61, 133, 214);
}
.head-am2_ylp {
	font-size: 48px;
	text-align: start;
	font-family: "anton", sans-serif;
	color: rgb(34, 96, 205);
}

.part_d1_ylp p {
	font-size: 33px;
	color: white;
}
.con-mind2 {
  /* margin-left: 20px; */
	padding-left: 30px;
	padding-right: 30px;
	text-align: left;
  margin-right: 20px;
	font-size: 22px;
	padding-bottom: 5px;
}
.con-mind2_ylp {
	text-align: left;
  margin-left: 50px;
  margin-right: 20px;
	font-size: 22px;
	padding-bottom: 5px;
}
.part_d1_ylp {
	width: 100%;
	max-width: 95vw;
	display: flex;
	flex-direction: row;
	/* justify-content: space-around; */
	align-items: start;
	padding-top: 15px;
	padding-left: 50px;
}
.forest-am2 {
	display: flex;
	padding-bottom: 20px;
	flex-direction: column;
	opacity: 0.02px;
}
.forest_img {
	background-image: url("./images/About/mn.webp");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 700px;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
/* .ylp_img {
	background-image: url("./images/ylp/YLP\ image\ 1\ desk.webp");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 700px;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
} */
.forest_img button {
	margin-top: 430px;
	background-color: black;
	color: white;
	border-bottom: 6px solid white;
	font-size: 46px;
	font-weight: 600;
}
.forestam {
	width: 100vw;
	align-items: center;
	padding: 10px 0 10px 0;
}
.forestam_ylp {
	align-items: center;
	padding: 40px 0 40px 0;
}
.forestam img {
	width: 100%;
	height: auto;
}
.forestam_ylp img {
	width: 100%;
	height: auto;
}
.ylp_line {
	text-align: center;
	font-weight: 900;
	margin: 0 10px 0 10px;
}
.ending-ylp {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 30px;
	margin: 20px 0 20px 0;
}
.proover {
	font-family: anton;
	padding: 20px;
	color: rgb(0, 120, 225);
	/* border-top: 1.5px solid black; */
}
.info-strip1 {
	padding-top: 25px;
	margin-bottom: 25px;
}

.pro-strip {
	display: flex;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 15px;
	justify-content: space-evenly;
}
.img-item img {
	height: 100px;
}
.grey-text {
	margin-left: 12px;
	margin-right: 12px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	background-color: rgb(240, 240, 240);
	margin-bottom: 0px;
}
.text1 {
	margin-top: 30px;
	padding-left: 40px;
	padding-right: 40px;
	font-size: 40px;
	font-weight: 650;
	text-align: center;
}
.text2 {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 98px;
	color: rgb(51, 168, 82);
	font-weight: 300;
	font-family: "Anton", sans-serif;
	text-align: center;
}
.text3 {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 38px;
	font-weight: 650;
	margin-bottom: 40px;
	text-align: center;
}
.prakriti {
	margin-top: 20px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.img-item img {
		height: 75px;
	}
	.text1 {
		font-size: 30px;
	}
	.text2 {
		font-size: 70px;
	}
	.text3 {
		font-size: 27px;
	}
}

@media (max-width: 768px) {
	.head-am2_ylp {
		font-size: 40px;
	}
	.pro-strip {
		display: flex;
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 20px;
		margin-bottom: 5px;
	}
	.img-item img {
		height: 30px;
	}
	.con-mind2_ylp {
		margin-left: 20px;
		font-size: 22px;
	}
	.part_d1_ylp {
		margin-left: 20px;
		padding-left: 0px;
	}
	.forest-am2 {
		display: flex;
		flex-direction: column;
		opacity: 0.02px;
	}
	.grey-text {
		margin-left: 2px;
		margin-right: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 15px;
		text-align: justify;
		margin-bottom: 0px;
	}
	.text1 {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 18px;
		font-weight: 500;
	}
	.text2 {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 40px;
		color: rgb(51, 168, 82);
		font-weight: 300;
		font-family: "Anton", sans-serif;
	}
	.text3 {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 40px;
	}

	.prakriti {
		content: url("./images/pics/mob.webp");
		width: 100%;
		height: auto;
	}
}

@media (max-width: 768px) {
	.forest_img {
		height: 250px;
	}
	/* .ylp_img {
		height: 250px;
	} */
	.forest_img button {
		margin-top: 125px;
		font-size: 20px;
	}
	.head-am2 {
		font-size: 20px;
		text-align: left;
	}
	.part_d1 {
		padding-left: 10px;
		padding-right: 10px;
	}
	.part_d1 p {
		font-size: 16px;
	}
	.con-mind2 {
		font-size: 12px;
    /* margin-left: 10px;
    margin-right: 10px; */
		padding-left: 10px;
		padding-right: 10px;
	}
	.proover {
		padding-bottom: 0;
	}
	.forestam img {
		width: 100%;
	}
	.forestam_ylp img {
		width: 100%;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.forest_img {
		height: 500px;
	}
	.forestam_ylp img {
		width: 100%;
		content: url('./images/ylp/YLP\ image\ 2\ \(mobi\).png');
	}
	/* .ylp_img {
		height: 500px;
	} */
	.forest_img button {
		margin-top: 300px;
		font-size: 30px;
	}
	.head-am2 {
		font-size: 30px;
	}
	.part_d1 p {
		font-size: 20px;
	}
	.con-mind2 {
		font-size: 14px;
	}
}
