.contact-us-heading {
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact-us-main-container {
  margin-bottom: 50px;
  margin: 40px;
	padding: 40px;
	border: 0.6px dotted black;
}
/* .contact-us-form {

} */
.contact-us-form-items {
  display: flex;
	flex-wrap: wrap;
  width: 50%;
}

.contact-us-items-form {
  width: 90%;
	padding: 10px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	position: relative;
	text-align: left;
	align-items: start;
	justify-content: space-between ;
	display: flex;
	font-size: 20px;  
}

.contact-us-items-form input {
	border: 1px solid rgb(26, 26, 26);
	width: 85%;
  margin-left: 20px;
}

.contact-us-items-form textarea {
  border-radius: 5px;
	border: 1px solid rgb(26, 26, 26);
	width: 85%;
  margin-left: 20px;
}

.contact-us-button {
  width: 50%;
  display: flex;
  margin-top: 30px;
  /* align-items: center;
  justify-content: center; */
}

.contact-us-button button {
  padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

@media (max-width: 768px) {
  .contact-us-main-container {
    /* margin-bottom: 50px;
    margin-top: 50px; */
    margin: 40px 20px 50px 20px;
    padding: 10px;
    border: 0.6px dotted black;
  }
  .contact-us-form-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .contact-us-button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .contact-us-items-form {
    font-size: 14px;
  }
}

/* Ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .contact-us-form-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .contact-us-button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}