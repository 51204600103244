/* .Aff-p {
	display: flex;
	justify-content: space-between;
	padding: 10px 30px 10px 30px;
	padding-right: 40px;
	padding-left: 40px;
} */

.aff-h1 {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 48px;
	margin-top: 30px;
	/* letter-spacing: -2px; */
}

.aff-h1-home {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 48px;
	margin-top: 30px;
	/* letter-spacing: -2px; */
}

.aff-p-1-container {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}


.aff-p-1 {
	font-weight: 600;
	font-size: 18px;
}

.aff-hero-btn {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-size: 23px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	;
	color: #fff;
}


.aff-hero {
	display: flex;
	justify-content: space-around;
}

.aff-hero-left {
	justify-content: left;
	text-align: left;
}

.aff-hero-subheading {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 32px;
	margin-top: 30px;
}

.aff-hero-img {
	width: 450px;
	overflow: hidden;
}

.aff-hero-img img {
	width: 100%;
}

.aff-hero-subheading-2 {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 32px;
	margin-bottom: 20px;
}

/* .aff-hero-right{

} */

.aff-hero-right-img {
	width: 500px;
}
.aff-hero-right-img img {
	width: 100%;
}

.aff-unite{
	background-color: #ececec;
	padding-top: 10px;
	margin-top: 50px;
	margin-bottom: 50px;
}

.aff-unite-h1{
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 10px;
}

.aff-unite-subheading {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 30px;
	margin-bottom: 40px;
}

.space{
	height: 10px;
}


.aff-partners-and-affiliates {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 80px;
	margin-top: 50px;
}

.aff-partners-and-affiliates-logo-img-container {
	width: 200px;	
}

.aff-partners-and-affiliates-logo-img-container img{
	width: 100%;
}

.aff-partners-and-affiliates-heading{
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 10px;
}

.aff-partners-and-affiliates-img-container {
	width: 90vw;
}

.aff-partners-and-affiliates-img-container img{
	width: 100%;
}




.Aff-button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

.Aff-button:hover {
	background-color: rgb(0, 47, 66);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.Coll-button {
	width: 380px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 5px;
	font-size: 20px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

.Coll-button:hover {
	background-color: rgb(0, 47, 66);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.aff-youth-programs-container {
	display: flex;
	justify-content: space-around;
	margin-bottom: 50px;
}

.aff-youth-program-1 {
	width: 30vw;
	padding: 10px;
	border-radius: 15px;
	background-color: #ececec;
}

.aff-youth-program-heading {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 27px;
	margin-top: 30px;
	margin-bottom: 25px;
}

.aff-youth-program-1-bold-p {
	color: rgb(0, 120, 225);
	font-weight: 700;
	font-size: 20px;
	line-height: 25px;
}

.aff-youth-program-1-p {
	color: rgb(0, 120, 225);
	font-weight: 500;
	font-size: 18px;
}

.aff-youth-program-2 {
	width: 30vw;
	padding: 10px;
	border-radius: 15px;
	background-color: #ececec;
}

.aff-youth-program-2-p {
	color: rgb(0, 120, 225);
	font-weight: 500;
	font-size: 18px;
}

.aff-youth-program-3 {
	width: 30vw;
	padding: 10px;
	border-radius: 15px;
	background-color: #ececec;
}

.aff-youth-program-heading-3 {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 27px;
	margin-top: 30px;
	margin-bottom: 5px;
}

.aff-youth-program-3-big-text {
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 44px;
	margin-bottom: 30px;
}


.wasi-container {
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 10px;
	flex-wrap: wrap;
}




.wasi-2-btns-container {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 50px;
}



.wasi-2-btns-heading {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 45px;
	margin-top: 30px;
	margin-bottom: 15px;
}

.wasi-2-btns-btn {
	padding: 10px;
	padding-right: 25px;
	padding-left: 25px;
	border: none;
	font-size: 20px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	color: #fff;
}

.aff-wasi-p {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
}



.aff-impc-container {
	display: flex;
	flex-direction: column;
	margin: 50px 0;
	background-color: #ececec;
}

.aff-impc-heading {
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.aff-impc-heading-blue {
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 48px;
	margin-top: 50px;
	margin-bottom: 20px;
}

.aff-impc-ahp {	
	font-weight: 600;
	color: rgb(0, 120, 225);
	font-size: 25px;
	/* margin-top: 30px; */
	margin-bottom: 10px;
}

.aff-impc-subcontainer{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.aff-impc-subcontainer-left{
	width: 340px;
	background-color: white;
	padding: 25px 0px;
	padding-left: 40px;
	padding-right: 40px;
}

.aff-impc-subcontainer-left-heading {
	font-weight: 700;
}

.aff-impc-subcontainer-left-p {
	font-size: 16px;
	color: rgb(0, 120, 225);
}

.aff-impc-subcontainer-right {
	width: 340px;
	margin: 20px;
	padding-left: 20px;
	background-color: white;
}

.aff-impc-subcontainer-right img{
	width: 100%;
}

.aff-impc-p {
	width: 80vw;
	font-weight: 600;
	font-size: 20px;
	align-self: center;
	margin-top: 10px;
	margin-bottom: 40px;
}

.aff-impc-btn {
	width: 280px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-size: 20px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	color: #fff;
	align-self: center;
	justify-self: center;
	margin-bottom: 30px;
}


.wtayswim-p {
	font-size: 20px;
	font-weight: 600;
	color: rgb(0, 120, 225);
}

.wtayswimi-btn{
	width: 280px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-size: 20px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	color: #fff;
	align-self: center;
	justify-self: center;
	margin-bottom: 30px;
}






.aff-new-prog {
	display: flex;
	padding-right: 35px;
	padding-left: 35px;
	justify-content: space-between;
}

.head-af {
	font-size: 35px;
	text-align: center;
	padding-top: 15px;
	font-family: "Quicksand", sans-serif;
}
.af-content {
	padding-left: 160px;
	padding-right: 160px;
	font-size: 25px;
	text-align: center;
	font-weight: 900;
}
.af-content a {
	text-decoration: none;
}

.school-form {
	margin: 40px;
	padding: 40px;
	border: 0.6px dotted black;
}

.school-form-head {
	font-size: 30px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	text-align: left;
}

.school-form-items {
	display: flex;
	flex-wrap: wrap;
}

.additional-info-p {
	width: 100%;
	text-align: start;
	font-size: 25px;
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.additional-info-p p {
	width: 100%;
	margin-bottom: 0;
	font-weight: 500;
}

.additional-info-p span {
	margin-bottom: 0;
	font-weight: 900;
}

.additional-info-div {
	margin-top: 20px;
	width: 100%;
	display: flex;
	/* align-items: start;
	justify-content: start; */
	text-align: start;
	margin-left: 10px;
}

.left-text-payment {
	width: 70%;
	font-size: 21px;
}

.ltp-1 {
	font-weight: 900;
	margin-bottom: 0px;
}

.ltp-2 {
	font-weight: 900;
	color: #1875d1;
	font-size: 25px;
}
.ltp-3 {
	font-weight: 900;
}

.right-img-qr {
	width: 30%;
}

.right-img-qr img {
	width: 100%;
}

.razorpay_pay {
	display: flex;
	flex-direction: column;
}

.rp-1 {
	margin-bottom: 0;
	font-size: 25px;
}
.rp-2 {
	margin-bottom: 0;
	font-size: 25px;
}

.razorpay-btn {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.rp-3 {
	font-weight: 900;
	font-size: 28px;
	margin-right: 10px;
	margin-bottom: 0;
}

.razorpay-btn button {
	width: 150px;
	padding: 0;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-size: 20px;
	font-weight: 700;
	border-radius: 15px;
	text-align: center;
	background-color: rgb(0, 47, 66);
	color: #fff;
}

.razorpay-btn button:hover {
	background-color: rgb(0, 120, 225);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.school-items-form {
	width: 90%;
	padding: 10px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	position: relative;
	text-align: left;
	align-items: center;
	justify-content: space-between;
	display: flex;
	font-size: 20px;
}
.school-items-form input {
	position: absolute;
	border: 1px solid rgb(26, 26, 26);
	right: 40px;
	width: 45%;
}

.up-dec-button {
	width: 10%;
	padding: 5px 15px 5px 15px;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-weight: 700;
	border-radius: 15px;
	text-align: center;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

.up-dec-button:hover {
	background-color: rgb(0, 47, 66);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.pay-btn {
	padding: 5px 15px 5px 15px;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 47, 66);
	color: #fff;
}

.pay-btn:hover {
	background-color: rgb(0, 120, 225);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.btn-common {
	margin: 10px;
	display: flex;
	justify-content: space-between;
}
.last_buttons {
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
}

.error {
	color: red;
	background: pink;
	border: 1px solid red;
	border-radius: 4px;
	padding: 8px;
	margin: 10px 0;
}
.after-submit {
	width: 50%;
	display: none;
	border: 0.7px solid black;
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 20px;
}
.after-submit button {
	background-color: rgb(0, 120, 225);
	border: none;
	border-radius: 20px;
	color: white;
	padding: 5px 10px 5px 10px;
	font-weight: 700;
}

.why-affiliate-container {
	width: 99vw;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 20px;
	gap: 20px;
	justify-items: center;
	margin-top: 50px;
	margin-bottom: 50px;
	grid-row-gap: 50px;
}
.aff-benifit-container {
	width: 220px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	border: 1px solid black;
	border-radius: 20px;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	height: 400px; /* Set a fixed height of 400px */
  box-sizing: border-box;
	/* text-align: start; */
}
.aff-benifit-container img {
	width: 130px;
	margin-bottom: 10px;
	/* text-align: center; */
}
.aff-benifit-container-title {
	font-weight: bolder;
	color: #1875d1;
	font-size: 18px;
	/* margin-top: 10px; */
	font-family: "Quicksand";
	margin-bottom: 20px;
}

.aff-benifit-container-button {
	border: 1px solid black;
	border-radius: 50%;
	color: white;
	background-color: black;
}

.aff-benifit-container-p {
	font-size: 15px;
	font-weight: 600;
	margin-top: 20px;
	/* text-align: start; */
}

.perk-benefit {
	padding-right: 30px;
	padding-left: 30px;
	padding-bottom: 30px;
	/* display: flex; */
	justify-content: space-between;
}
.perk-benefit span {
	font-size: 30px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	color: rgb(35, 116, 162);
}
.perk-benefit button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(47, 136, 214);
	color: #fff;
}
.com-perk-eli {
	padding-right: 30px;
	padding-left: 30px;
}
.com-perk-eli p {
	text-align: left;
	font-weight: 600;
}
.com-perk-eli li {
	text-align: left;
	font-weight: 600;
}
.perk-head {
	color: rgb(17, 128, 224);
	font-weight: 600;
}
.perk-head1 {
	color: rgb(0, 0, 0);
	font-weight: 600;
}
.eli-criteria {
	padding: 30px;
	font-size: 30px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	color: rgb(40, 148, 86);
	text-align: left;
}
.eli-head {
	color: rgb(27, 161, 85);
	font-weight: 600;
}
.last_check {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border: 0.6px dotted black;
	margin-top: 10px;
	border-radius: 20px;
}
.last_check_box input {
	padding: 40px;
}
.last_check div {
	padding: 10px;
}
.last_check p {
	text-align: justify;
	padding: 10px;
	font-weight: 600;
}

.collapse-buttons {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.imcc-btn {
	margin: 20px;
}

.icc-main {
	text-align: left;
	width: 70vw;
}

.imcc-main-content p span {
	font-weight: 900;
}

@media (max-width: 768px) {

	.why-affiliate-container {
		max-width: 99vw;
		overflow: hidden;
		margin-top: 20px;
		grid-row-gap: 10px;
		justify-content: space-around;
		grid-template-columns: repeat(2, 1fr);
	}

	.aff-partners-and-affiliates-img-container {
		display: none;
	}

	.aff-h1 {
		font-size: 38px;
	}

	.aff-unite-subheading {
		font-size: 24px;
	}

	.aff-hero {
		display: flex;
		max-width: 99vw;
		/* justify-content: space-around; */
		justify-content: center;
		flex-wrap: wrap;
		/* max-width: 100vw; */
		/* overflow: hidden; */
	}
	
	.aff-hero-left {
		justify-content: center;
		text-align: center;
		display: flex;
		flex-direction: column;
	}
	
	.aff-hero-subheading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 25px;
		margin-top: 30px;
	}

	.aff-unite-h1{
		font-size: 36px;
	}
	
	.aff-hero-img {
		align-self: center;
		width: 300px;
		overflow: hidden;
	}
	
	.aff-hero-img img {
		width: 100%;
	}

	.aff-hero-btn {
		width: 300px;
		align-self: center;
		padding: 5px;
		padding-right: 5px;
		padding-left: 5px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
	}
	
	.aff-hero-subheading-2 {
		font-family: anton;
		font-weight: 400;
		color: #1875d1;
		font-size: 26px;
		margin-bottom: 20px;
	}
	
	.aff-hero-right-img {
		width: 300px;
	}
	.aff-hero-right-img img {
		width: 100%;
	}


	.aff-youth-programs-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: column;
		margin-bottom: 50px;
	}
	
	.aff-youth-program-1 {
		width: 90vw;
		margin-bottom: 20px;
	}
	
	.aff-youth-program-heading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 27px;
		margin-top: 30px;
		margin-bottom: 25px;
	}

	.aff-partners-and-affiliates-heading{
		font-size: 38px;
	}
	
	.aff-youth-program-1-bold-p {
		color: rgb(0, 120, 225);
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;
	}
	
	.aff-youth-program-1-p {
		color: rgb(0, 120, 225);
		font-weight: 500;
		font-size: 18px;
	}
	
	.aff-youth-program-2 {
		width: 90vw;
		margin-bottom: 20px;
	}
	
	.aff-youth-program-2-p {
		color: rgb(0, 120, 225);
		font-weight: 500;
		font-size: 18px;
	}
	
	.aff-youth-program-3 {
		width: 90vw;
	}
	
	.aff-youth-program-heading-3 {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 27px;
		margin-top: 30px;
		margin-bottom: 5px;
	}

	.aff-h1-home {
		font-family: anton;
		font-weight: 400;
		color: #1875d1;
		font-size: 30px;
		margin-top: 30px;
		/* letter-spacing: -2px; */
	}
	
	.aff-youth-program-3-big-text {
		font-family: anton;
		font-weight: 400;
		color: rgb(0, 120, 225);
		font-size: 44px;
		margin-bottom: 30px;
	}

	.wasi-container {
		font-family: anton;
		font-weight: 400;
		color: rgb(0, 120, 225);
		font-size: 30px;
		margin-top: 30px;
		margin-bottom: 10px;
		flex-wrap: wrap;
	}
	
	.wasi-2-btns-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 50px;
	}
	
	.wasi-2-btns-heading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 25px;
		margin-top: 30px;
		margin-bottom: 15px;
	}
	
	.wasi-2-btns-btn {
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
	}
	
	.aff-wasi-p {
		padding-left: 40px;
		padding-right: 40px;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	}

	.Coll-button {
		width: 300px;
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-weight: 700;
		border-radius: 5px;
		font-size: 16px;
		background-color: rgb(0, 120, 225);
		color: #fff;
	}
	
	.Coll-button:hover {
		background-color: rgb(0, 47, 66);
		transition-duration: 450ms;
		transition-delay: 75ms;
	}

	
	.aff-impc-container {
		display: flex;
		flex-direction: column;
		margin: 70px 0;
	}
	
	.aff-impc-heading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 34px;
		margin-top: 30px;
		margin-bottom: 10px;
	}
	
	.aff-impc-heading-blue {
		font-family: anton;
		font-weight: 400;
		color: rgb(0, 120, 225);
		font-size: 34px;
		margin-top: 70px;
		margin-bottom: 20px;
	}
	
	.aff-impc-ahp {	
		font-weight: 600;
		color: rgb(0, 120, 225);
		font-size: 20px;
		/* margin-top: 30px; */
		margin-bottom: 10px;
	}
	
	.aff-impc-subcontainer{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
	}
	
	.aff-impc-subcontainer-left{
		width: 340px;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		height: 130px;
		padding-right: 5px;
		padding: 0px;
	}
	
	.aff-impc-subcontainer-left-heading {
		font-weight: 700;
	}
	
	.aff-impc-subcontainer-left-p {
		font-size: 16px;
		color: rgb(0, 120, 225);
	}
	
	.aff-impc-subcontainer-right {
		width: 340px;
		padding-left: 20px;
	}
	
	.aff-impc-subcontainer-right img{
		width: 100%;
	}
	
	.aff-impc-p {
		width: 80vw;
		font-weight: 600;
		font-size: 20px;
		align-self: center;
		margin-top: 40px;
		margin-bottom: 40px;
	}
	
	.aff-impc-btn {
		width: 300px;
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
		align-self: center;
		justify-self: center;
		margin-bottom: 30px;
	}



	.wtayswim-p {
		font-size: 20px;
		padding: 15px;
		font-weight: 600;
		color: rgb(0, 120, 225);
	}
	
	.wtayswimi-btn{
		width: 300px;
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(0, 120, 225);
		color: #fff;
		align-self: center;
		justify-self: center;
		margin-bottom: 30px;
	}
	


	.additional-info-p {
		font-size: 20px;
	}
	.additional-info-div {
		flex-direction: column;
	}
	.right-img-qr {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.right-img-qr img {
		width: 80%;
	}
	.razorpay_pay {
		display: flex;
		flex-direction: column;
		text-align: left;
	}
	.rp-1 {
		margin-bottom: 0;
		font-size: 20px;
	}
	.rp-2 {
		margin-bottom: 0;
		font-size: 20px;
	}
	.rp-3 {
		font-weight: 900;
		font-size: 20px;
		margin-right: 10px;
		margin-bottom: 0;
	}
	.aff-benifit-container {
		width: 90%;
		height: auto;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
	}
	.aff-benifit-container img {
		width: 90px;
		/* margin-bottom: 10px; */
	}
	.aff-benifit-container-title {
		font-weight: bolder;
		color: #1875d1;
		font-size: 15px;
		margin-top: 10px;
		font-family: "Quicksand";
		margin-bottom: 15px;
	}
	.aff-benifit-container-p {
		font-size: 12px;
		font-weight: 600;
		/* text-align: start; */
	}
	.school-items-form {
		width: 100%;
		font-size: 10px;
		padding: 7px;
	}
	.school-form {
		margin: 20px;
		padding: 20px;
	}

	.up-dec-button {
		font-size: 12px;
	}
	.pay-btn {
		font-size: 12px;
	}
	.school-items-form input {
		right: 0px;
		width: 40%;
	}
	.up-dec-button {
		width: 30%;
	}
	.last_buttons {
		padding-top: 13px;
	}
	.aff-new-prog {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
	}
	.perk-benefit span {
		font-size: 25px;
	}
	.perk-benefit button {
		font-size: 20px;
		width: 90%;
	}
	.last_check {
		padding: 5px;
	}
	.last_check div {
		padding: 3px;
	}
	.last_check p {
		font-size: 8px;
		padding: 4px;
	}
	.Aff-p {
		font-size: 15px;
	}
	.Aff-button {
		/* padding-right: 5px;
      padding-left: 5px;
      font-size: 15px; */
		width: 320px;
	}
	.af-content {
		padding-left: 40px;
		padding-right: 40px;
		font-size: 15px;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.Aff-button {
		font-size: 15px;
	}

	.aff-hero-btn {
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 23px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
	}
	
	
	.aff-hero {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	
	.aff-hero-left {
		justify-content: center;
		text-align: center;
	}
	
	.aff-hero-subheading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 32px;
		margin-top: 30px;
	}
	
	.aff-hero-img {
		width: 450px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	
	.aff-hero-img img {
		width: 100%;
		align-self: center	;
	}
	
	.aff-hero-subheading-2 {
		font-family: anton;
		font-weight: 400;
		color: #1875d1;
		font-size: 32px;
		margin-bottom: 20px;
	}
	
	/* .aff-hero-right{
	
	} */
	
	.aff-hero-right-img {
		width: 400px;
	}
	.aff-hero-right-img img {
		width: 100%;
	}

	.why-affiliate-container {
		max-width: 100%;
		margin-top: 20px;
		grid-row-gap: 20px;
		grid-template-columns: repeat(4, 1fr);
	}
	.aff-benifit-container {
		width: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
	}
	.aff-benifit-container img {
		width: 90px;
	}
	.aff-benifit-container-title {
		font-weight: bolder;
		color: #1875d1;
		font-size: 15px;
		margin-top: 10px;
		font-family: "Quicksand";
		margin-bottom: 15px;
	}
	.aff-benifit-container-p {
		font-size: 12px;
		font-weight: 600;
		/* text-align: start; */
	}

	.school-items-form {
		width: 100%;
		font-size: 13px;
		padding: 10px;
	}

	.school-items-form input {
		right: 0px;
		width: 60%;
	}

	.up-dec-button {
		width: 30%;
	}
}
