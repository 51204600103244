@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Comme:wght@200&family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}


/* @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap"); */

body {
	overflow-x: hidden;
}
.top_comp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 25px;
}
.left_img {
	height: 100px;
	width: 250px;
	object-fit: contain;
}
.right_img {
	height: 90px;
	width: 230px;
	object-fit: contain;
}
.top_comp h2 {
	font-family: "Quicksand";
	margin-top: 24px;
	font-weight: 400;
	letter-spacing: 0.5px;
	line-height: 0.5px;
}
.aff {
	font-weight: 400;
	color: #1875d1;
	font-size: 50px;
	margin-top: 30px;
	font-family: "Quicksand";
}
.aff-black {
	font-weight: 400;
	color: #000000;
	font-size: 60px;
	margin-top: 30px;
	font-family: "Quicksand";
	letter-spacing: -2px;
}
.aff1 {
	font-weight: 400;
	color: #1875d1;
	font-size: 80px;
	margin-top: 10px;
	font-family: "Quicksand";
	margin-bottom: 10px;
}
.aff1-home {
	font-weight: 400;
	color: #1875d1;
	font-family: anton;
	font-size: 48px;
	margin-top: 100px;
	margin-bottom: 10px;
}
.aff2 {
	font-weight: 600;
	color: #000000;
	font-size: 30px;
	/* margin-top: 30px; */
	font-family: "Quicksand";
}
.custom-bg {
	width: 100vw;
	background: #231e21 !important;
	padding-top: 30px;
	/* background: white !important; */
}
.img_gallery {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 10px;
}
.gallery {
	height: 270px;
	width: 25%;
	padding: 10px;
}
.logos_grid {
	display: flex;
}
.logos_grid_img {
	width: 50%;
	height: auto;
}
.youtube_gal {
	display: flex;
	height: 350px;
	justify-content: center;
	padding-top: 20px;
	/* align-items: center; */
	padding-right: 30px;
	padding-left: 30px;
	grid-gap: 20px;
	gap: 20px;
}
.youtube_gal_iframe {
	width: 80%;
	height: 200px;
}
.youtube_gal_cards {
	width: 30%;
	height: 315px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #231e21;
	align-items: center;
	/* background-color: rgb(239, 239, 239); */
}
.youtube_gal_cards p {
	font-size: small;
	color: white;
}
.ylp-homepage-btn {
	margin-top: 10px !important;
}
.about {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background-image: url("./images/INDIAMUN/youth\ back.webp"); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #ececec;
	/* margin-top: 20px; */
	padding: 40px;
	padding-bottom: 10px !important;
	/* margin-bottom: 20px; */
}

.habout {
	font-family: anton;
	font-size: 48px;
	font-weight: 400;
	color: rgb(0, 120, 225);
	text-align: center;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 20px;
}

.pabout {
	text-align: center;
	font-weight: 700;
}

.fabout {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 0;
	color: rgb(220, 156, 19);
	font-weight: 800;
	font-size: 25px;
}

.my-container {
	display: flex;
	/* justify-content: space-between; */
	/* align-items: center; */
	/* background-color: #231e21; */
	padding: 10px 10px 0px 10px;
	box-sizing: border-box;
}

.left-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 55%;
}

.upper-text {
	/* font-size: 24px; */
	/* font-weight: bold; */
	font-size: 90px;
	line-height: 1.2;
	margin-top: 80px;
	font-family: "Anton", sans-serif;
	/* font-weight: 100; */
	color: rgb(17, 128, 224);
	text-align: left;
}

.lower-text {
	font-family: "Comme", sans-serif;
	font-size: 18px;
	text-align: left;
	font-weight: bold;
}

.lower-text1 {
	font-family: "Comme", sans-serif;
	font-size: 24px;
	font-weight: bold;
	color: rgb(17, 128, 224);
	margin-bottom: 12px;
}

.lower-text-home {
	font-family: "Comme", sans-serif;
	color: white;
	font-size: 24px;
	text-align: left;
	font-weight: bold;
}

.right-text-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: left;
	width: 41%;
}

.right-img-plaque {
	width: 110%;
}

.right-text {
	margin-bottom: 10px;
	margin-top: 80px;
	flex: 1 1;
	font-family: "Comme", sans-serif;
	color: white;
	font-size: 40px;
}

.button-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.right-button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
	margin-top: 20px;
	margin-bottom: 40px;
}
.right-button-1 {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
	margin-top: 30px;
	margin-bottom: 40px;
}
.left-container {
	padding-left: 60px;
}
.program_mun {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.program_mun_content1 {
	display: flex;
	flex-direction: column;
	width: 32%;
}
.program_mun_content2 {
	display: flex;
	flex-direction: column;
	width: 20%;
	padding-left: 40px;
}
.program_mun_content2 div {
	margin: auto;
	justify-content: space-around;
}
.program_mun_content3 {
	display: flex;
	flex-direction: column;
	width: 48%;
}
.program_mun_content3 div {
	margin: auto;
	padding: 30px 100px 30px 100px;
}
.program_mun_content3 div p {
	border-top-style: solid;
	border-top-width: 4px;
	border-image: linear-gradient(
			90deg,
			rgba(255, 176, 36, 1) 0%,
			rgba(255, 109, 144, 1) 35%,
			rgba(148, 8, 103, 1) 100%
		)
		1;
	justify-content: flex-start;
	text-align: justify;
	font-weight: 900;
	padding-top: 20px;
	color: rgb(84, 84, 85);
}
.program_mun_content4 {
	display: flex;
	align-items: center;
	justify-content: center;
	/* flex-direction: column; */
	width: 100%;
}
.program_mun_content4 img {
	width: 90vw;
}
/* .underline-small{
        display:inline-block;
        position:relative;
        
      }
    
      .underline-small::after{
        content:'';
        height:3px;
        width:110%;
        background:linear-gradient(90deg, rgba(98,249,233,1) 0%, rgba(0,194,232,1) 35%, rgba(98,135,254,1) 100%);
        position:absolute;
        left:calc(30% - 35%);
        bottom:-5px;
      } */
.con {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.h-con {
	font-family: anton;
	font-weight: 400;
	border-bottom-style: solid;
	border-bottom-width: 4px;
	border-image: linear-gradient(
			90deg,
			rgba(98, 249, 233, 1) 0%,
			rgba(0, 194, 232, 1) 35%,
			rgba(98, 135, 254, 1) 100%
		)
		1;
	margin-top: 30px;
	font-size: 48px;
	font-weight: 400;
	color: rgb(0, 120, 225);
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 40px;
}
.h-con1 {
	border-bottom-style: solid;
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	border-bottom-width: 4px;
	border-image: linear-gradient(
			90deg,
			rgba(98, 249, 233, 1) 0%,
			rgba(0, 194, 232, 1) 35%,
			rgba(98, 135, 254, 1) 100%
		)
		1;

	color: rgb(0, 120, 225);
	text-align: center;
	text-transform: uppercase;
	margin: 40px auto;
	width: 50%;
}

.button_div_climate2 {
	display: flex;
	flex-direction: column;
}

.button_div_climate2 p {
	margin-top: 30px;
}

.p-con {
	text-align: center;
	font-weight: bolder;
	padding: 5px 10px 10px 10px;
}

.p-con-div {
	display: flex;
	align-items: center;
	width: 90vw;
}

.f-con {
	text-align: center;
	/* margin-top: 20px; */
	margin-bottom: 30px;
	color: rgb(0, 120, 225);
	font-size: 20px;
	font-weight: 800;
}

.f-con button {
	font-size: 15px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
	margin-bottom: 15px;
}
.annual-conference-logo {
	width: 100px;
}
.con_div {
	margin-top: 20px;
	/* background-image: url("./images/INDIAMUN/bg.png"); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}
.image-card {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/* min-width: 100vw; */
	overflow-x: hidden !important;
	margin: 0 auto;
	width: 100%;
	transition: box-shadow 0.3s ease-in-out;
	/* border: 2px solid #ddd; */
	margin: 10px 0 10px 0;
	background-color: #231e21;
	padding-top: 10px;
	padding-bottom: 10px;
}
.text-container p {
	color: white;
	font-weight: 600;
	font-size: 20px;
	text-align: left;
	padding-left: 29px;
}
.text-container span {
	font-family: anton;
	font-weight: 400;
	font-size: 40px;
	color: rgb(0, 120, 225);
	font-weight: 400;
}

.image-container {
	width: 40%;
	margin: 0;
	padding: 0;
}

.image-container img {
	width: 90%;
	height: auto;
}

.text-container {
	width: 55%;
	padding: 20px;
}
.mobile_photo {
	display: none;
}
.h-col {
	margin-top: 40px;
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	color: rgb(0, 120, 225);
	text-align: center;
	text-transform: uppercase;
}
.p-col {
	font-family: "Quicksand";
	text-align: center;
	color: rgb(84, 84, 85);
}
.col-new {
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin-bottom: 50px; */
}
.col-new-div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.col-new-div img {
	width: 35%;
	height: auto;
}
.col-new-div p {
	font-family: "Quicksand";
	margin-top: 20px;
	text-align: center;
	font-size: 27px;
}
.col-new-div i {
	/* justify-content: center;
          align-items: center; */
	padding: 15px;
}
.fa-lg {
	display: none;
}
.news-events {
	display: flex;
	width: 100%;
	height: auto;
	grid-gap: 12px;
	gap: 12px;
	padding: 30px;
}
.news {
	width: 60%;
	background-color: rgb(217, 217, 217);
	padding-left: 30px;
}
.news img {
	width: 50%;
	height: auto;
	margin-bottom: 10px;
}
.news_t {
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	text-align: left;
	padding: 5px;
	font-size: 40px;
	padding-left: 40px;
}
.news_p {
	text-align: left;
	padding: 30px 30px 15px 30px;
	font-size: 20px;
	font-weight: 400;
	text-decoration: underline;
}
.news_pc {
	text-align: justify;
	padding: 10px 30px 10px 30px;
	font-size: 20px;
	font-weight: 400;
}
.news_td {
	display: flex;
	flex-direction: column;
}
.news_td button {
	width: 15%;
	margin-left: 30px;
}

.climate-hack-container {
	display: flex;
	flex-direction: column;
	justify-content: start !important;
	margin-top: 0 !important;
}

.climate-hack-p {
	margin-bottom: 8px !important;
	margin-top: 0 !important;
}
.climate-hack-p-1 {
	margin-bottom: 8px !important;
	margin-top: 0 !important;
}

.climate-hack-button {
	margin-top: 0 !important;
}

.climate-hack-span {
	margin-top: 0 !important;
}

.events {
	width: 40%;
	background-color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.events_t {
	color: white;
	font-family: anton;
	font-weight: 400;
	text-align: left;
	padding: 5px;
	padding-left: 40px;
	font-size: 40px;
}
.events img {
	width: 70%;
	height: auto;
}
.events_p {
	text-align: justify;
	padding: 30px;
	color: white;
	font-size: 20px;
	font-weight: 400;
}
.founding {
	margin-top: 20px;
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
}
.footer {
	width: 100%;
	height: auto;
	/* background-color: black; */
	background-image: url(/static/media/1-5.15f515f8.webp);
	padding: 15px 10px 50px 10px;
}
.footer_flex {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.footer_flex_item {
	display: flex;
	flex-direction: row;
	padding: 20px;
}
.footer_flex_item_p {
	color: white;
	text-align: center;
	margin: 12px;
}
.copy_footer {
	color: white;
	text-align: center;
	margin-right: 110px;
}
.footer_flex_item i {
	color: white;
	margin: 10px;
}
.founding_p {
	width: 30%;
	height: auto;
	margin-bottom: 40px;
}
.activism {
	text-align: center;

	color: white;
	background-image: url("/static/media/title image.559e537b.webp");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.activism h1 {
	padding-top: 20px;
	font-family: anton;
	font-weight: 400;
	font-weight: 300;
	font-size: 40px;
}

.nur {
	font-size: 15px;
	text-transform: uppercase;
	color: rgb(1, 242, 255);
	padding-bottom: 50px;
	font-weight: 700;
}
.main-act {
	font-size: 24px;
	letter-spacing: 1.15px;
	font-weight: 700;
}

.roller {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding-bottom: 180px;
}
.roller button {
	display: block;
	position: relative;
}
.roller i {
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
}
.join-ind {
	display: inline-block;
	margin-top: 2rem;
}
.join-ind p {
	font-size: 25px;
	margin-bottom: 0;
}

.join-but {
	background-color: rgb(24, 117, 209);
	color: white;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: 0.5px solid white;
	border-radius: 10px;
	margin-bottom: 10px;
}
.up {
	margin-bottom: 1px;
}
.mid {
	margin-bottom: 1px;
	font-size: 20px;
	color: rgb(255, 192, 4);
}
.sec {
	padding-bottom: 15px;
}
.twitter_feed {
	margin-bottom: 40px;
	max-width: 100vw;
}
.twitter_feed p {
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 48px;
	font-weight: 400;
}
.twit {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid rgba(59, 59, 59, 0.5);
	border-radius: 20px;
	grid-gap: 14px;
	gap: 14px;
	width: 80%;
	margin: auto;
}
.img-slide-car {
	/* padding: 10px; */
	background-color: white;
	margin-left: 25px;
	margin-right: 25px;
	margin-bottom: 60px;
}
.img-slide-car img {
	aspect-ratio: 16/9;
	object-fit: contain;
	/* mix-blend-mode: color-burn; */
	background-color: white;
}

.button_div_climate {
	display: flex;
	flex-direction: row;
}

.button_div_climate div {
	display: flex;
	flex-direction: column;
	/* padding: 0 10px 10px 10px; */
}
.button_div_climate div p {
	color: rgb(190, 49, 174);
	font-weight: 700;
}
.button_div_climate div span {
	margin-top: -15px;
	font-weight: 800;
}
.button_div_climate div button {
	font-size: 14px;
	margin-top: -15px;
}
.reg-af {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 20px 20px 5px 20px;
	background: linear-gradient(
		90deg,
		rgba(1, 1, 1, 1) 0%,
		rgba(55, 55, 55, 1) 50%,
		rgba(114, 114, 114, 1) 100%
	);
}
.reg-af div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.reg-af div p {
	color: white;
	font-size: 25px;
	font-weight: 400;
}
.right-button1 {
	width: 50%;
	padding: 6px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}
.right-button2 {
	width: 19%;
	padding: 5px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}
.regi {
	margin-top: 120px;
}
.regi h1 {
	font-size: 30px;
	font-weight: 400;
	color: rgb(43, 143, 66);
	text-align: center;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 20px;
}
.end_sth {
	display: flex;
	justify-content: space-evenly;
	padding-left: 600px;
	padding-right: 600px;
}

.blogs-main-heading {
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	font-weight: 400;
	color: rgb(0, 120, 225);
	margin-top: 30px;
	margin-bottom: 20px;
}

.blogs {
	width: 100vw;
	display: flex;
	overflow: hidden;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 50px;
}

.blog {
	height: 300px;
	display: flex;
	flex-direction: column;
	padding: 20px 30px;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 30vw;
	cursor: pointer;
	border: 2px solid rgba(59, 59, 59, 0.5);
	border-radius: 20px;
}

.readmore-span {
	color: #1875d1;
	font-weight: 800;
}

.head-am {
	display: flex;
	margin-top: 20px;
	font-size: 35px;
	text-align: center;
	justify-content: center;
	padding-top: 25px;
	font-family: "Quicksand", sans-serif;
	color: rgb(43, 143, 66);
}
.head-am span {
	font-size: 20px;
	text-align: center;
	padding-top: 10px;
	padding-left: 5px;
	color: rgb(0, 120, 225);
	/* justify-content: center; */
}

.con-mind {
	text-align: center;
	font-size: 17px;
	font-weight: 800;
}
.forest-am {
	/* padding-left: 5px; */
	padding-right: 35px;
	display: flex;
}

.for-left {
	background-image: url(/static/media/Forrest-left.d7d6ac9b.webp);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 355px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.for-left button {
	margin-top: 65px;
	background-color: black;
	color: white;
	border: 3px solid white;
	border-top: none;
	border-right: none;
	border-left: none;
}

.for-right {
	align-items: center;
	justify-content: center;
	display: flex;
}

.for-right p {
	font-size: 10px;
	text-align: justify;
	padding-right: 25px;
	padding-left: 25px;
	margin-top: -30px;
	font-weight: 500;
}

.for-right img {
	width: 100%;
	height: 200px;
	margin-top: -80px;
}
.part {
	width: 50%;
	flex-wrap: wrap;
}

.for-right button {
	margin-top: -70px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}
.margin-button {
	margin-top: -150px;
}
.img_end {
	padding-bottom: 40px;
}
.img_end img {
	width: 100%;
	height: 100px;
}

.reg_school_clg {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.reg_school_clg_fa {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
@keyframes flicker {
	20% {
		color: rgba(255, 0, 0, 0.8);
	}
	40% {
		color: rgba(0, 255, 0, 0.8);
	}
	60% {
		color: rgba(0, 0, 255, 0.8);
	}
	80% {
		color: rgba(255, 165, 0, 0.8);
	}
	100% {
		color: rgb(200, 0, 255);
	}
}
.reg_school_clg span {
	font-size: 24px;
	font-weight: 900;
	color: black;
	/* color: rgb(78, 167, 116); */
	/* animation: flicker 0.6s infinite; */
}
.reg_school_clg_fa span {
	margin-right: 20px;
	font-size: 24px;
	font-weight: 900;
	color: black;
	/* color: rgb(78, 167, 116); */
	/* animation: flicker 0.6s infinite; */
}
.reg_school_clg a button {
	padding-right: 50px;
	padding-left: 50px;
	font-size: 24px;
	font-weight: 700;
}
.reg_open {
	font-size: 16px;
	font-weight: 400;
	/* animation: flicker 0.6s infinite; */
	padding-bottom: 5px;
}
.drop_col_down {
	margin-bottom: 30px;
}
.drop_col1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.drop_col2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.drop_col2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
.drop_col11 {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	justify-content: center;
	align-items: center;
}
.drop_col h3 {
	font-size: 20px;
}
.drop_col button {
	width: 60%;
}
.drop_col .reg_open {
	margin-bottom: 0;
}
/* .drop_col .button_div_climate button{
  width: 100%;
}
.drop_col .button_div_climate{
  gap: 10px;
} */

.main-cola {
	display: flex;
	margin-bottom: 20px;
}
.cola-items {
	display: flex;
	flex-direction: column;
	width: 33%;
}
.cola-items p {
	font-size: 18px;
	font-weight: 600;
}
.cola-items button {
	background-color: rgb(0, 120, 225);
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 20px;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 20px;
	border-radius: 8px;
	font-weight: 600;
	color: white;
	border: none;
}
.img-cola {
	display: flex;
	align-items: center;
	justify-content: center;
}
.img-cola i {
	padding: 30px;
}
.img-cola img {
	width: 40%;
	height: auto;
}

@media screen and (max-width: 1200px) {
	.image-card {
		width: 100%;
	}
}

@media screen and (max-width: 992px) {
	.image-card {
		justify-content: flex-start;
		text-align: center;
		/* width: 85vw;
		min-width: none; */
	}

	.twitter_feed {
		display: none;
	}

	/* .youtube_gal {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 1350px;
	} */

	.text-container {
		width: 100%;
		padding: 10px;
	}

	.image-container {
		width: 100%;
		margin-bottom: 20px;
	}
}

@media (max-width: 768px) {
	.twitter_feed {
		display: none;
	}
	.top_comp {
		padding: 5px 20px;
	}
	.right_img {
		display: none;
	}

	.ylp-homepage span {
		display: flex;
		line-height: 1;
	}

	.reg_open {
		display: flex;
		line-height: 1;
	}

	.news_t {
		text-align: center !important;
		margin-right: 70px;
	}

	.events_t {
		margin-right: 30px;
	}

	.habout {
		font-size: 28px;
	}

	.reg_school_clg_fa {
		margin-top: 20px !important;
	}

	.reg_school_clg_fa span {
		margin-right: 20px;
		font-size: 15px !important;
	}

	.navbar-toggler {
		background-color: white;
	}

	.my-container {
		flex-direction: column-reverse;
		justify-content: center;
	}
	.left-container {
		width: 100%;
	}
	.right-text-container {
		width: 90%;
	}
	.founding {
		font-size: 28px;
	}
	.right-text {
		font-size: 20px !important	;
	}

	.climate-hack-p-1 {
		margin-bottom: 13px !important;
	}

	/* .climate-hack-p:first-child {
		margin-bottom: 10px !important;
	} */

	.blogs {
		flex-direction: column;
	}
	
	.blog {
		height: auto;
		padding: 20px 30px;
		width: 90vw;
		margin-bottom: 20px;
	}

	.top_comp .left_img {
		height: 40px;
		width: 110px;
	}
	.top_comp h2 {
		font-size: 12px;
		margin-bottom: 30px;
		line-height: 0;
		letter-spacing: 0;
	}
	.img_gallery {
		width: 100%;
		display: flex;
		/* flex-direction: column; */
	}
	.gallery {
		height: 150px;
		min-width: 50%;
	}
	.aff {
		font-size: 30px;
	}
	.aff1 {
		font-size: 40px;
	}
	.aff2 {
		font-size: 20px;
	}
	.right-text {
		font-size: 10px;
	}
	.lower-text {
		font-size: 10px;
	}
	.lower-text1 {
		font-size: 10px;
	}
	.upper-text {
		font-size: 30px;
	}
	.left-container {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-left: 0;
	}
	.logos_grid {
		flex-direction: column;
	}
	.logos_grid_img {
		width: 100%;
		height: auto;
	}
	.youtube_gal {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 1000px;
	}
	.youtube_gal_cards {
		width: 95%;
	}
	.youtube_gal_iframe {
		width: 80%;
	}
	.aff1-home {
		font-size: 28px;
	}
	.pabout {
		font-size: 10px;
	}
	.h-col {
		font-size: 28px;
	}
	.image-card {
		/* justify-content: flex-start; */
		flex-direction: column-reverse;
		text-align: center;
		width: 100%;
	}
	.text-container span {
		font-size: 22px;
	}
	.text-container p {
		font-size: 20px;
	}
	.image-container {
		width: 80%;
		height: auto;
	}
	.mobile_photo {
		display: block;
		width: 100%;
		height: auto;
	}
	.program_mun_content1 img {
		display: none;
	}
	.program_mun_content4 img {
		display: none;
	}
	.program_mun {
		flex-direction: column;
	}
	.program_mun_content2 {
		width: 100%;
		flex-direction: row;
		padding-left: 10px;
	}
	.program_mun_content2 div {
		margin: 3px;
	}
	.program_mun_content2 div span {
		font-size: 10px;
	}
	.program_mun_content3 {
		width: 100%;
		flex-direction: column;
	}
	.program_mun_content3 div p {
		width: 100%;
	}
	.program_mun_content3 div {
		width: 100%;
		padding: 20px;
	}
	.program_mun_content2 div h3 {
		font-size: 12px;
		font-weight: bolder;
	}
	.program_mun_content2 div .right-button {
		font-size: 10px;
	}
	.col-new {
		flex-direction: column;
	}
	.col-new-div {
		width: 100%;
		padding-right: 10px;
	}
	.col-new-div img {
		width: 30%;
		height: auto;
	}
	.col-new-div p {
		text-align: center;
		font-size: 20px;
		padding-right: 15px;
	}
	/* .sc2{
  margin-left: 10px;
} */
	.footer_flex {
		flex-direction: column;
	}
	.footer_flex_item {
		padding: 0px;
	}
	.copy_footer {
		margin: auto;
	}
	.founding_p {
		width: 60%;
	}
	.news-events {
		flex-direction: column;
	}
	.news {
		width: 100%;
	}
	.events {
		width: 100%;
	}
	.main-act {
		padding-bottom: 50px;
	}
	.nur {
		padding-bottom: 20px;
	}
	.activism h1 {
		font-size: 25px;
	}
	.main-act {
		font-size: 15px;
	}
	.mid {
		font-size: 15px;
	}
	.up {
		font-size: 12px;
	}
	.join-but {
		margin-bottom: 20px;
	}
	.twit {
		flex-direction: column;
	}
	.news_td button {
		width: 30%;
	}
	.roller button {
		margin-bottom: 60px;
	}
	.right-button2 {
		width: 30%;
		font-size: 10px;
	}
	.margin-button {
		margin-top: -100px;
	}
	.regi {
		margin-top: 40px;
	}
	.right-button1 {
		width: 100%;
		margin-bottom: 4px;
	}
	.reg-af div {
		padding: 10px;
	}
	.reg-af div p {
		font-size: 15px;
	}
	.forest-am {
		flex-direction: column;
	}
	.part {
		width: 100%;
	}
	.for-left {
		height: 200px;
	}
	.for-left button {
		margin-top: 18px;
		font-size: 12px;
	}
	.for-right img {
		height: 120px;
	}
	.for-right button {
		margin-top: 23px;
	}
	.head-am {
		flex-direction: column;
		font-size: 30px;
	}
	.h-con1 {
		width: 80%;
		font-size: 28px;
	}
	.h-con {
		font-size: 28px;
	}
	.about {
		padding: 10px 20px 10px 20px;
	}
	.reg_school_clg a button {
		padding-left: 8px;
		padding-right: 8px;
		font-size: 15px;
	}
	.reg_school_clg span {
		font-size: 15px;
		margin-top: 20px;
	}
	.reg_open {
		font-size: 10px;
	}
	.button_div_climate div p {
		font-size: 10px;
	}
	.button_div_climate1 span {
		font-size: 10px;
	}
	.fa-2xl {
		display: none;
	}
	.fa-lg {
		display: block;
	}

	.col-new-div i {
		padding: 0px;
	}
	.end_sth {
		padding-left: 100px;
		padding-right: 100px;
	}
	.main-cola {
		flex-direction: column;
	}
	.cola-items {
		width: 100%;
	}
}

@media screen and (max-width: 576px) {
	.text-container span {
		font-size: 22px;
	}
	.twitter_feed {
		display: none;
	}
	.text-container p {
		font-size: 20px;
	}
	.underline-small::after {
		left: calc(8% - 10%);
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.mobile_photo {
		display: block;
		width: 100%;
		height: auto;
	}
	.program_mun_content1 img {
		display: none;
	}
	.program_mun_content4 img {
		display: none;
	}
	.program_mun {
		flex-direction: column;
	}
	.program_mun_content2 {
		width: 100%;
		flex-direction: row;
		padding-left: 1px;
	}
	.program_mun_content2 div {
		margin: 0px 20px;
	}
	.cli_phone {
		padding-left: 30px;
	}
	.program_mun_content3 {
		width: 100%;
		flex-direction: column;
	}
	.program_mun_content3 div p {
		width: 100%;
	}
	.program_mun_content3 div {
		width: 100%;
		padding: 20px;
	}
	.program_mun_content2 div h3 {
		font-size: 12px;
	}
	.program_mun_content2 div .right-button {
		font-size: 10px;
	}
	.col-new-div {
		width: 50%;
	}
	.col-new-div img {
		width: 40%;
		height: auto;
	}
	.col-new-div p {
		text-align: center;
		font-size: 15px;
	}
	.left-container {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-left: 5px;
	}
	.top_comp h2 {
		font-size: 20px;
	}
	.blogs {
		flex-direction: column;
	}
	
	.blog {
		height: auto;
		padding: 20px 30px;
		width: 90vw;
		margin-bottom: 20px;
	}
	.twit {
		flex-direction: column;
	}
	.main-act {
		font-weight: 10px;
	}
	.for-right img {
		height: 100px;
	}
	.for-left {
		height: 250px;
	}
	.for-left button {
		margin-top: 30px;
	}
	.end_sth {
		padding-left: 250px;
		padding-right: 250px;
	}
	.reg_school_clg a button {
		padding-left: 8px;
		padding-right: 8px;
		font-size: 17px;
	}
	.reg_school_clg span {
		font-size: 20px;
		margin-bottom: 5px;
	}
}


.nav_class {
	background-color: rgb(37, 37, 36);
	margin-bottom: 0px;
}
.login-btn-main {
	height: 100%;
	text-align: center;
	padding-top: 8px;
}
.nav_items {
	text-decoration: none;
	color: white;
	font-family: "Quicksand";
	margin-right: 0px;
	font-weight: 900;
	font-size: 16px;
	padding-right: 0px;
}
.nav_dropdown_items {
	text-decoration: none;
	color: black;
	font-family: "Quicksand";
	margin-right: 0px;
	font-weight: 900;
	font-size: 16px;
	padding-right: 0px;
}
.nav_items:active {
	color: white;
}
.nav_items:hover {
	color: white;
}
#nav_custom {
	color: white;
}
.big_nav {
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: auto;
}
.user_main {
	display: flex;
}
.user_pic {
	width: 20px;
	border-radius: 50%;
}
/* .dropdown-menu[data-bs-popper] {
	left: -250% !important;
} */
/* .dropdown-item {
	display: flex;	
	justify-content: center;
} */
.logout_btn {
	padding: 2px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
	/* margin-top: 20px;
	margin-bottom: 20px; */
}
.logout-btn {
	border: none;
}
.user_pic_container {
	margin-right: 0px !important;
}
.left {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	width: 80vw;
	/* justify-content: space-around; */
}
.right {
	width: 20vw;
}
.right-nav {
	height: 100%;
	margin-right: 140px;
	display: flex;
	align-items: center;
	justify-content: end;
}
@media (max-width: 900px) {
	.nav_items {
		margin-right: 0;
		margin-left: 0;
		font-size: 10px;
	}
	.big_nav {
		margin: auto;
		flex-direction: column;
	}
	.left {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.right-nav {
		justify-content: start;
	}
}

.site-footer
{
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.text-justify{
    text-align: justify;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
  text-align: left;
}
.site-footer a
{
  color:#737373;
  text-decoration: none;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none;
  text-align: left;
  text-decoration: none;
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:center
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0;
  text-align: left;
}



.foot_img{
    width: 100%;
    height: 150px;
}

.social-icons .x-twitter {
  color: #1DA1F2; /* Twitter Blue */
}
.social-icons .facebook {
  color: #3b5998; /* Facebook Blue */
}
.social-icons .instagram {
  color: #e4405f; /* Instagram Pink/Red */
}
.social-icons .linkedin {
  color: #0077b5; /* LinkedIn Blue */
}
.social-icons .youtube {
  color: #FF0000; /* YouTube Red */
}

@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
  .foot_img{
    width: 80%;
    height: 140px;
}
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
.foot_img{
    width: 100%;
    height: 110px;
}

}
.testimonial-container {
  max-width: 100%;
	display: flex;
	/* height: auto !important; */
	align-items: center;
	text-align: center;
	justify-content: center;
	margin-top: 60px;
	margin-right: 30px;
	margin-left: 30px;
	margin-bottom: 20px;
	grid-gap: 100px;
	gap: 100px;
}

.testimonial-main-div {
	width: 500px;
	height: 400px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.yt_video {
	aspect-ratio: 16/9;
	width: 300px;
}

.image {
	width: 100%;
}

.image img {
	width: 70px;
	border-radius: 50%;
	margin-bottom: 15px;
}

.testimonial-person-details {
	display: flex;
	margin-top: 15px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.main-info {
	/* margin-left: 10px; */
	display: flex;
	flex-direction: column;
	text-align: center;
	font-weight: 900;
}

.main-info h5 {
	margin-bottom: 0;
}

.testimonial-name {
	font-size: 15px;
	font-weight: 900;
}

.testimonial-position {
	font-size: 15px;
	font-weight: 900;
}

.testimonial-authority {
	font-size: 12px;
	font-weight: 500 !important;
}

.testimonial-mainSource {
	font-weight: 400;
	color: #1875d1;
	font-size: 18px;
	font-family: "Quicksand";
	font-weight: 900;
}

@media (max-width: 768px) {
  .testimonial-container {
    max-width: 99%;
    /* height: auto !important; */
    flex-direction: column;
		margin-bottom: 0;
		grid-gap: 20px;
		gap: 20px;
  }

	.testimonial-main-div {
		width: 85vw;
		/* height: 300px; */
		margin-bottom: 30px;
	}

	.yt_video {
		width: 100%;
		height: 200px;
	}

	.image {
		width: 20%;
	}

	.testimonial-person-details {
		height: auto;
	}

	.testimonial-person-details {
		display: flex;
		margin-top: 5px;
	}

	.main-info {
		/* height: 1000px; */
	}

	.testimonial-name,
  .testimonial-authority,
  .testimonial-position,
	.testimonial-mainSource {
		font-size: 12px;
	}

	.testimonial-mainSource {
		font-size: 14px;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.testimonial-container {
    max-width: 99%;
    height: auto !important;
		align-items: center;
    flex-direction: column;
  }

	.testimonial-main-div {
		width: 75vw;
		height: auto;
		margin-bottom: 30px;
	}

	.yt_video {
		width: 100%;
		height: 300px;
	}

	.image {
		width: 15%;
	}

	.testimonial-person-details {
		display: flex;
		margin-top: 15px;
	}

  .testimonial-name,
  .testimonial-authority,
  .testimonial-position,
	.testimonial-mainSource {
		font-size: 20px;
	}

	.testimonial-mainSource {
		font-size: 23px;
	}
}

.ab {
	font-size: 48px;
	padding: 40px;
	color: rgb(50, 92, 155);
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
}
.main-about-imgs {
	width: 100vw;
}
.main-about-imgs img {
	width: 100%;
}
/* Styles for desktop view */
.desktop-img {
  display: block;
}
.mobile-img {
	display: none;
}
.ap {
	font-size: 16px;
	font-weight: 700;
	padding: 0 100px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 40px;
	text-align: justify;
	display: flex;
	flex-direction: column;
}
.read p {
	padding: 20px 60px 20px 60px;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
	color: white;
	background-color: rgb(6, 33, 50);
}

.about-values {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	margin-bottom: 40px;
	padding: 0 110px;
}

.about-values-title-container {
	text-align: right;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	/* position: relative; */
}

.about-values-title {
	font-weight: 900;
	margin-bottom: 10px;
}

.about-values-title-underline {
	align-self: flex-end;
	width: 200px;
	height: 2px;
	background-color: #000;
	margin-top: 2px;
}

.about-values-points-container {
	display: flex;
	flex-direction: column;
}

.about-values-point {
	display: flex;
	width: 100%;
}

.about-values-icon {
	width: 60px;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.about-values-icon p {
	font-size: 40px;
}

.about-values-text {
	text-align: justify;
	padding-left: 20px;
	display: flex;
	font-size: 19px;
}

.about-values-text span {
	font-weight: 900;
}

.more {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 30px;
}
.mored img {
	height: 112px;
}
.mored h4 {
	padding: 16px 0 10px 0;
	font-size: 30px;
	font-weight: 800;
}
.mored p {
	font-size: 16px;
	font-weight: 700;
}
.text-bold {
	font-size: 40px;
	margin-bottom: 10px;
	align-self: center;
	justify-self: center;
}
.sus-eco {
	background-color: rgb(236, 245, 245);
	padding-right: 65px;
	padding-left: 65px;
	padding-top: 40px;
	padding-bottom: 50px;
}
.sus-eco h1 {
	text-align: center;
	font-weight: 700;
	padding-bottom: 80px;
	font-family: "Anton";
}
.sus-eco p {
	font-size: 20px;
	text-align: left;
	font-weight: 600;
	padding-bottom: 15px;
}
.scrolling-div {
	position: relative;
	overflow: auto;
	height: 300px; /* Adjust the height as needed */
}

.content {
	background-image: url(/static/media/pexels-mikhail-nilov-7475711-1-1-scaled.c0be23f6.webp);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	position: relative;
	z-index: 1;
	padding: 20px; /* Adjust the padding as needed */
	height: 300px;
}

.content::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: inherit;
	z-index: -1;
}
.voice {
	padding: 0 20px;
}
.voice p {
	font-size: 20px;
	padding: 60px;
	background-color: rgb(242, 242, 242);
	font-weight: 700;
}
.bid_i p {
	font-size: 50px;
	word-spacing: 25px;
}
.bid_i img {
	width: 100%;
	height: auto;
}
.bid_i {
	margin-top: 40px;
	margin-bottom: 40px;
}

.wh-youth-div {
	padding-left: 45vw;
	background-image: url(/static/media/aboutus1.82e13e99.webp);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 600px;
	padding-right: 40px;
}
.wh-youth-div h1 {
	text-align: center;
	color: white;
	padding-top: 50px;
	padding-bottom: 30px;
}
.wh-youth-div p {
	color: white;
	font-size: 20px;
	padding-bottom: 20px;
	text-align: justify;
}

@media (max-width: 768px) {
	.ab {
		font-size: 25px;
		padding: 5px;
	}
	.mobile-img {
    display: block;
  }

  .desktop-img {
    display: none;
  }
	.ap {
		font-size: 18px;
		padding: 5px;
	}
	.about-values {
		padding: 0 15px;
	}
	
	.about-values-title-underline {
		width: 160px;
	}
	
	.about-values-icon {
		width: 40px;
		align-items: start;
	}
	
	.about-values-icon p {
		font-size: 32px;
	}
	
	.about-values-text {
		text-align: left;
		padding-left: 10px;
		font-size: 15px;
	}
	
	.about-values-text span {
		font-weight: 900;
	}
	.read p {
		font-size: 12px;
		padding: 20px 30px 20px 30px;
	}
	.mored img {
		height: 50px;
	}
	.mored h4 {
		font-size: 15px;
	}
	.mored p {
		font-size: 10px;
	}
	.sus-eco {
		padding-bottom: 10px;
		padding: 10px 20px 10px 20px;
	}
	.sus-eco h1 {
		padding-bottom: 15px;
		font-size: 20px;
	}
	.sus-eco p {
		font-size: 12px;
	}
	.img_end img {
		height: 90px;
	}
	.content {
		background-image: url(/static/media/mobile.defcd55e.webp);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.bid_i p {
		font-size: 20px;
		word-spacing: 10px;
	}
	.wh-youth-div h1 {
		font-size: 25px;
	}
	.wh-youth-div p {
		font-size: 10px;
	}
	.wh-youth-div {
		background-image: url(/static/media/about-us-half.53d73467.webp);
		padding-left: 40px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 400px;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.about-values {
		padding: 0 90px;
	}
	.about-values-text {
		font-size: 16px;
	}
	.wh-youth-div h1 {
		font-size: 25px;
		padding-bottom: 5px;
	}
	.wh-youth-div p {
		font-size: 15px;
		padding-bottom: 2px;
	}
	.wh-youth-div {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 400px;
	}
}

.headname span{
    /* background: rgb(45,181,252); */
    background-color: rgb(37, 37, 36);
    color: white;
    /* background: linear-gradient(90deg, rgba(45,181,252,1) 0%, rgba(158,174,236,1) 50%, rgba(255,164,215,1) 100%); */
    padding: 5px 60px;
    border-radius: 30px;
    /* color: rgb(0, 120, 225); */
	font-family: anton;
    letter-spacing: 1px;
	font-weight: 400;
	/* font-size: 48px; */
}
.headname1 span{
    /* background: rgb(77,189,132);
    background: linear-gradient(90deg, rgba(77,189,132,1) 0%, rgba(143,208,117,1) 50%, rgba(200,226,100,1) 100%);  */
    background-color: rgb(37, 37, 36);
    color: white;
    padding: 5px 60px;
    border-radius: 30px;
    font-family: anton;
	font-weight: 400;
    letter-spacing: 1px;
}
.headname1{
    text-align: center;
   font-size: 30px;
}
.headname{
   text-align: center;
   font-size: 30px;
}
.img_main_div{
    display: flex;
    flex-direction: column;
    padding: 40px;
}
.img_main_div1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
}
.img_main_div2{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 30px;
}
.img_main_div3{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.img_main_div1 img{
    width: auto;
    height: 100px;
}
.img_main_div2 img{
    width: auto;
    height: 100px;
}
.img_main_div3 img{
    width: auto;
    height: 140px;
}


@media (max-width: 768px){

.headname{
    font-size: 15px;
}
.headname1{
    font-size: 15px;
}
.img_main_div{
    padding: 0px;
}
.img_main_div1{
    padding:10px;
}
.img_main_div2{
    
    padding: 10px;
}
.img_main_div3{
    padding: 10px;
}
.img_main_div1 img{
    width: auto;
    height: 35px;
}
.img_main_div2 img{
    width: auto;
    height: 35px;
}
.img_main_div3 img{
    width: auto;
    height: 35px;
}


}




@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .img_main_div1{
        padding:10px;
    }
    .img_main_div2{
        
        padding: 10px;
    }
    .img_main_div3{
        padding: 10px;
    }
    .img_main_div1 img{
        width: auto;
        height: 50px;
    }
    .img_main_div2 img{
        width: auto;
        height: 50px;
    }
    .img_main_div3 img{
        width: auto;
        height: 50px;
    }
    


}
.participate-item p {
	font-weight: 900 !important;
}
.participate {
	display: flex;
	padding: 20px;
}
.participate-item {
	display: flex;
	flex-direction: column;
	width: 20%;
	padding: 15px;
	justify-content: center;
	align-items: center;
}
.participate-item img {
	width: 18vw;
	height: auto;
	padding-bottom: 10px;
}
@keyframes flicker {
	20% {
		color: rgba(255, 0, 0, 0.8);
	}
	40% {
		color: rgba(0, 255, 0, 0.8);
	}
	60% {
		color: rgba(0, 0, 255, 0.8);
	}
	80% {
		color: rgba(255, 165, 0, 0.8);
	}
	100% {
		color: rgb(200, 0, 255);
	}
}
.reg_parti {
	animation: flicker 0.6s infinite;
	background-color: white !important;
	border: 2px solid rgb(0, 120, 225) !important;
}
.participate-item a {
	width: 100%;
}
.participate-item button {
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	border: none;
	padding: 5px;
	width: 100%;
	color: white;
	align-items: center;
	cursor: pointer;
	margin-bottom: 3px;
}
.participate-item i {
	cursor: pointer;
}
.Participate-head {
	font-size: 35px;
	text-align: center;
	color: rgb(159, 75, 149);
}
.india {
	text-align: center;
	font-size: 60px;
	margin-top: -20px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
}
.mod {
	text-align: center;
	font-size: 20px;
	margin-top: -10px;
	font-family: "Quicksand", sans-serif;
	font-weight: 500;
}
.year {
	text-align: center;
	font-size: 30px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
}
.reg-info {
	display: flex;
	width: 100%;
	max-width: 100%;
	height: 80px;
	justify-content: start;
	align-items: center;
	margin-left: 80px;
}
.reg-info h2 {
	margin-bottom: 0px !important;
	color: rgb(19, 106, 17);
	font-size: 23px;
	font-weight: bolder;
	margin-right: 20px;
}
.reg-info button {
	border-radius: 5px;
	background-color: rgb(0, 120, 225);
	font-weight: bolder;
	border: none;
	padding: 5px;
	width: 150px;
	color: white;
	align-items: center;
	cursor: pointer;
	margin-bottom: 3px;
}
.img-syca {
	width: 100%;
}
.img-syca img {
	width: 100%;
}
.imagesection {
	/* background-image: url('./images/newimages/login\ deks\ .webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
	/* height: 930px; */
	position: relative;
}
.imagesection .custom-button {
	position: absolute;
	top: 47%;
	right: 10%;
	width: 15%;
	padding: 7px;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: white;
	/* background-color: rgb(0, 120, 225); */
	color: black;
	font-size: 16px;
	border: 0.5px solid black;
}
.imagesection .custom-button1 {
	position: absolute;
	top: 42%;
	right: 10%;
	width: 15%;
	padding: 7px;
	font-weight: 700;
	border-radius: 10px;
	background-color: white;
	/* background-color: rgb(0, 120, 225); */
	color: black;
	font-size: 20px;
	text-align: left;
}
.imagesection .custom-button2 {
	position: absolute;
	top: 32%;
	right: 10%;
	width: 15%;
	padding: 7px;
	font-weight: 700;
	border-radius: 10px;
	background-color: white;
	/* background-color: rgb(0, 120, 225); */
	color: black;
	font-size: 20px;
	text-align: left;
}
.imagesection .custom-button3 {
	position: absolute;
	top: 37%;
	right: 10%;
	width: 15%;
	padding: 7px;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: white;
	/* background-color: rgb(0, 120, 225); */
	color: black;
	font-size: 16px;
	border: 0.5px solid black;
}
.custom-button:hover {
	background-color: rgb(64, 157, 244);
	color: white;
	cursor: pointer;
}
.custom-button3:hover {
	background-color: rgb(64, 157, 244);
	color: white;
	cursor: pointer;
}
.head-am1 {
	font-size: 40px;
	text-align: center;
	font-family: "Quicksand", sans-serif;
	color: rgb(43, 143, 66);
}
.head-am1_fa {
	font-size: 48px;
	text-align: start;
	/* color: rgb(0, 120, 225); */
	font-family: anton;
	font-weight: 400;
	color: rgb(32, 136, 55);
}
.part_d {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding-top: 15px;
}
.part_d_fa {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: start;
	padding-top: 15px;
	text-align: start;
	padding-left: 55px;
}
.part_d p {
	font-size: 25px;
	color: rgb(61, 133, 214);
}

.date-and-timeline {
	text-align: left;
	font-size: 30px;
	padding-left: 40px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	color: rgb(40, 148, 86);
}

.lm-btn {
	margin-top: 5px !important;
}
.ul-type {
	text-align: left;
	padding-left: 4px;
	margin-left: 40px;
	border-left: 2px solid black;
	padding-top: 3px;
	padding-bottom: 5px;
	font-weight: 600;
}
.ul-type li::marker {
	content: "\2022";
	font-size: 25px;
	font-weight: bolder;
}

.am-content {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 17px;
	font-weight: 600;
	text-align: justify;
}
.am-who {
	color: rgb(55, 127, 200);
	font-weight: 600;
}

.intro-mod {
	text-align: left;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	font-size: 25px;
	padding-left: 40px;
	color: rgb(76, 144, 211);
}
.topics {
	text-align: left;
	padding-left: 60px;
	font-weight: 600;
	padding-right: 60px;
}
.topics li {
	text-align: justify;
}
.img-cer {
	/* padding-left: 40px; */
	/* padding-right: 40px; */
}
.certificate {
	display: flex;
	justify-content: space-around;
}
.certificate img {
	height: 250px;
	width: 100%;
}
.con-cer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* padding-left: 60px; */
}
.con-cer button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}
.con-cer div {
	font-size: 22px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.ylp-main {
	background-color: rgb(235, 233, 233);
	/* margin-right: -5px; */
}
.ylp {
	padding: 20px 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.ylp button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(235, 233, 233);
	color: black;
}
.ylp div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.ylp span {
	font-size: 48px;
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	padding-right: 10px;
	padding-left: 10px;
}
.ylp-pic {
	display: flex;
	padding: 25px;
}
.ylp-sec {
	padding-left: 50px;
}
.ylp-left {
	display: flex;
	align-items: start;
	padding-left: 20px;
}
.ylp-left img {
	width: 90%;
	height: 400px;
}
.ylp-right img {
	width: 100%;
	height: 400px;
}
.ylp-school-pic {
	padding: 20px;
}

.ylp-school-img img {
	width: 100%;
	height: auto;
}

.cli-hack {
	margin-top: 50px;
	padding-left: 100px;
	padding-right: 75px;
	display: flex;
	justify-content: space-between;
}
.cli-hack span {
	/* color: rgb(0, 120, 225); */
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	/* font-weight: 600; */
}
.cli-hack button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	font-size: 35px;
	background-color: rgb(37, 37, 36);
	color: #fff;
}
.climate-hack-container-part {
	display: flex !important;
	flex-direction: column;
}
.climate-hack-p-part {
	margin-bottom: 13px !important;
	margin-top: 30px;
}
.coming_soon {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(37, 37, 36);
	color: #fff;
}
.prev-ev {
	text-align: center;
	/* color: rgb(0, 120, 225); */
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	color: black;
	margin-top: 20px;
	margin-bottom: 20px;
}
.eve_img1 {
	display: none;
}
.insta-feed {
	display: flex;
	padding: 10px;
	flex-wrap: wrap;
}
.insta-feed1 {
	width: 25%;
	padding: 20px;
}
.bda_cli {
	background-color: rgb(37, 37, 36);
	color: white;
	padding-top: 5px;
	padding-bottom: 20px;
	margin-bottom: 30px;
}
.climate-hackathon {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: white;
}
.cli1 {
	display: flex;
	flex-direction: column;
	width: 33%;
	justify-content: center;
	align-items: center;
}
.cli1 img {
	height: 300px;
	width: 300px;
	padding: 10px;
}
.cli1 div {
	padding: 3px;
}
.cli1 h3 {
	text-align: left;
}
.cli2 {
	width: 33%;
	display: flex;
	flex-direction: column;
}
.cli2 div {
	display: flex;
	flex-direction: row;
}
.cli2 img {
	height: 240px;
	width: auto;
	padding: 5px;
}
.cli2 p {
	text-align: justify;
}
.cli3 {
	width: 33%;
}
.cli3 img {
	height: 510px;
	width: auto;
}

.IYFA {
	text-align: left;
	font-size: 30px;
	padding-left: 40px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	color: rgb(40, 148, 86);
}
.young-for {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 17px;
	text-align: justify;
	text-align: left;
	font-weight: 600;
}
.com-IYFA {
	color: rgb(17, 128, 224);
	padding-top: 20px;
	font-size: 17px;
	text-align: left;
	font-weight: 600;
}

.model_img {
	height: 120px;
	margin-bottom: 30px;
}
.youth_full {
	background-color: rgb(235, 233, 233);
	padding-top: 20px;
	padding-bottom: 30px;
}
.youth_got_talent {
	display: flex;
	justify-content: space-between;
	padding-left: 40px;
	padding-right: 40px;
	align-items: center;
}
.youth_got_talent h1 {
	/* color: rgb(0, 120, 225); */
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
}
.youth_got_talent p {
	color: black;
	font-size: 20px;
	padding-right: 18px;
	font-weight: 700;
}
.youth_p {
	text-align: justify;
	padding-left: 40px;
	padding-right: 40px;
	font-weight: 500;
	font-size: 18px;
}
.youth_img {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 40px;
	padding-left: 40px;
	margin-bottom: 40px;
}
.youth_img img {
	width: 50%;
	height: auto;
}
.cli-conf {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 40px;
	padding-right: 40px;
	grid-gap: 20px;
	gap: 20px;
}
.cli-conf1 {
	display: flex;
	flex-direction: column;
}
.cli-conf1 p {
	text-align: justify;
}
.cli-conf1 img {
	width: 100%;
	height: auto;
}
.cli-conf2 img {
	width: 95%;
	height: auto;
}
.live_prog {
	background-color: rgb(15, 15, 15);
	padding: 10px 10px 10px 20px;
	margin-top: 20px;
}
.live_prog h1 {
	text-align: left;
	color: white;
}

/* ipad */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.imagesection {
		/* height: 550px; */
	}
	.imagesection .custom-button {
		position: absolute;
		top: 50%;
		right: 7%;
		width: 20%;
		padding: 2px;
		/* padding-right: 15px;
        padding-left: 15px; */
		border: none;
		font-weight: 700;
		border-radius: 10px;
		background-color: white;
		/* background-color: rgb(0, 120, 225); */
		color: black;
		font-size: 12px;
		border: 0.5px solid black;
	}

	.imagesection .custom-button1 {
		font-size: 15px;
		top: 44%;
		right: 7%;
		width: 20%;
	}
	.imagesection .custom-button2 {
		font-size: 10px;
		top: 32%;
		right: 7%;
		width: 20%;
	}
	.imagesection .custom-button3 {
		font-size: 10px;
		top: 37%;
		right: 7%;
		width: 20%;
		padding: 2px;
	}

	.certificate img {
		height: 200px;
	}
	.ylp span {
		font-size: 32px;
	}
	.ylp-left img {
		width: 60vw;
		height: 300px;
	}
	.ylp-right img {
		width: 30vw;
		height: 300px;
	}
	/* .ylp-school-pic img{
        width: 95vw;
        height: 240px;
      } */
	.cli-hack {
		margin-top: 30px;
		padding: 10px;
	}
	.eve-img {
		margin-left: 20px;
		margin-right: 20px;
		height: 520px;
		width: auto;
	}
	/* .insta-feed{
        flex-direction: column;
      } */
	.insta-feed1 {
		width: 50%;
	}
	.climate-hackathon {
		display: flex;
		flex-direction: column;
	}
	.cli1 {
		width: 100%;
	}
	.cli2 {
		width: 100%;
	}
	.cli2 p {
		padding: 20px;
	}
	.cli2 img {
		width: 400px;
		height: 400px;
		padding: 10px;
	}
	.cli3 {
		width: 100%;
	}
	.cli3 img {
		height: 1050px;
	}
}

/* phone */
@media (max-width: 768px) {
	.imagesection {
		/* background-image: url('./images/newimages/login\ mobile.webp');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          height: 500px; */
	}
	.imagesection .img_sec_l {
		content: url("/static/media/login mobile.39d91837.webp");
	}
	.img-syca img {
		content: url("/static/media/start your climate action(mobile).bca974ed.webp");
	}
	.participate {
		flex-wrap: wrap;
	}
	.climate-hack-imgs {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.reg-info {
		width: 89vw;
		height: 80px;
		margin-left: 20px;
	}
	.reg-info h2 {
		font-size: 13px;
		margin-right: 10px;
	}
	.reg-info button {
		padding: 1px;
		width: 100px;
		margin-bottom: 3px;
	}
	.climate-hack-container-part {
		display: flex !important;
		flex-direction: column;
		
	}
	.climate-hack-p-part {
		margin-bottom: 13px !important;
		margin-top: 30px;
	}
	.participate-item {
		width: 50%;
		/* padding: 20px; */
	}
	.participate-item img {
		width: 100%;
	}
	.Participate-head {
		font-size: 20px;
		text-align: center;
		color: rgb(159, 75, 149);
	}
	.participate {
		padding: 0;
	}
	.imagesection .custom-button {
		font-size: 10px;
		top: 67%;
		right: 6%;
		width: 40%;
	}
	.imagesection .custom-button1 {
		font-size: 10px;
		top: 62%;
		right: 6%;
		width: 40%;
	}
	.imagesection .custom-button2 {
		font-size: 10px;
		top: 48%;
		right: 6%;
		width: 40%;
	}
	.imagesection .custom-button3 {
		font-size: 10px;
		top: 53%;
		right: 6%;
		width: 40%;
	}
	.head-am1 {
		font-size: 25px;
	}
	.head-am1_fa {
		font-size: 20px;
	}
	.part_d p {
		font-size: 10px;
	}
	.part_d_fa p {
		font-size: 15px;
		padding-left: 20px;
		text-align: left;
		margin-right: 25px;
	}
	.part_d_fa {
		padding-left: 20px;
	}
	.con-mind {
		font-size: 12px;
	}
	.certificate {
		padding: 20px;
	}
	.certificate img {
		height: 100px;
		padding-right: 10px;
	}
	.con-cer {
		/* padding-left: 0; */
	}
	.con-cer div {
		font-size: 10px;
	}
	.ylp span {
		font-size: 20px;
	}
	.ylp-sec {
		font-size: 15px;
	}
	.ylp button {
		font-size: 15px;
	}
	.ylp-left img {
		width: 100%;
		height: 130px;
	}
	.ylp-right img {
		width: 100%;
		height: 140px;
	}
	.ylp-school-pic img {
		width: 100%;
		height: auto;
	}
	.ylp-main {
		margin-top: 10px;
		/* margin-right: -50px; */
	}
	.cli-hack {
		padding-right: 0px;
	}
	.cli-hack span {
		font-size: 20px;
	}
	.cli-hack button {
		font-size: 10px;
	}
	.cli-hack {
		margin-top: 30px;
	}
	.eve_img {
		display: none;
	}
	.eve_img1 {
		display: block;
	}
	.prev-ev {
		font-size: 25px;
		padding-left: 25px;
	}
	.ul-type {
		/* margin-left: 10px; */
		word-wrap: break-word;
	}
	.ylp {
		padding: 10px;
	}
	.ylp div button {
		font-size: 10px;
	}
	.ylp div {
		flex-direction: column;
		padding: 10px;
	}
	.insta-feed {
		flex-direction: column;
	}
	.climate-hackathon {
		flex-direction: column;
	}
	.cli1 {
		width: 100%;
	}
	.cli2 {
		width: 100%;
		padding: 10px;
	}
	.cli2 img {
		width: 185px;
		height: 185px;
		padding: 10px;
	}
	.cli2 p {
		padding: 10px;
	}
	.cli3 {
		width: 100%;
	}
	.cli3 img {
		height: 500px;
	}
	.participate-item button {
		font-size: 10px;
	}
	.youth_img {
		flex-direction: column;
	}
	.youth_img img {
		width: 100%;
		padding-bottom: 20px;
	}
	.youth_got_talent h1 {
		font-size: 20px;
		font-weight: 800;
	}
	.youth_got_talent p {
		font-size: 16px;
	}
	.cli-conf {
		flex-direction: column;
	}
	.cli-conf2 img {
		width: 100%;
	}
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.left-part {
  flex: 0 0 60%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.right-part {
  flex: 0 0 40%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group input[type="checkbox"],
.form-group input[type="radio"] {
	display: flex;
	align-items: top;
  margin-right: 10px;
  margin-bottom: 2px;
	width: auto !important;


}

.form-group .checkbox-group,
.form-group .radio-group {
  display: flex;
  align-items: center;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.top_comp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  text-align: left;
}

.left_img,
.right_img {
  height: 50px;
}


@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-part,
  .right-part {
    flex: 1 1 100%;
  }
}

.inline-flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: right;
}

.otp-section {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.otp-section input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.otp_input{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: row;
}
.otp-section button {
  padding: 10px 20px;
  margin: 0 5 5 5;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.otp-continue{
  background-color: #4caf50 !important;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}
.otp-section button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}


.aff-benifit-container {
	width: 220px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	border: 1px solid black;
	border-radius: 20px;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	height: 400px; /* Set a fixed height of 400px */
  box-sizing: border-box;
	/* text-align: start; */
}
.aff-benifit-container img {
	width: 130px;
	margin-bottom: 10px;
	/* text-align: center; */
}
.aff-benifit-container-title {
	font-weight: bolder;
	color: #1875d1;
	font-size: 18px;
	/* margin-top: 10px; */
	font-family: "Quicksand";
	margin-bottom: 20px;
}

.aff-benifit-container-button {
	border: 1px solid black;
	border-radius: 50%;
	color: white;
	background-color: black;
}

.aff-benifit-container-p {
	font-size: 15px;
	font-weight: 600;
	margin-top: 20px;
	/* text-align: start; */
}

.ben-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 15px;
  width: 100%;
}


/* <div className="scroll-right"> */
.scroll-right {
  justify-content: space-between;
  max-height: 1140px; /* Set the maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}


.headGreen {
  color: rgb(236, 236, 236);
}
.headerContainer {
  text-align: center;
  padding-right: 5%;
  background-color: rgb(23, 34, 44);
  width: 100%;
  height: auto;
  margin: auto;
  overflow: hidden;
}

#mainNav {
  background-color: rgb(23, 34, 44);
  color: whitesmoke;
}
#mainNav div {
  display: inline;
}
#mainNav ul {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}
#mainNav a,
.headerContainer a {
  text-decoration: none;
  font-size: 18px;
  color: whitesmoke;
}

.headerContainer ul {
  text-align: center;
}
.headerContainer li {
  display: inline;
}
#headText {
  color: #0078e1;
  margin-top: 1%;
  font-size: 50px;
  padding-bottom: 20px;
}
#textNormal {
  font-size: 2.5em;
  color: bisque;
  margin-top: 0;
  margin-bottom: 40px;
}

.inputBox {
  width: 90%;
  height: auto;
  align-content: center;
  margin: auto;
  border-radius: 30px;
  max-width: 700px;
  background-color: rgb(23, 34, 44);

  box-shadow: 0 2.8px 2.2px rgba(4, 33, 56, 0.719),
    0 6.7px 5.3px rgba(4, 33, 56, 0.719),
    0 12.5px 10px rgba(4, 33, 56, 0.719) 0 22.3px 17.9px rgba(4, 33, 56, 0.719),
    0 41.8px 33.4px rgba(4, 33, 56, 0.719), 0 100px 80px rgba(4, 33, 56, 0.719);
}
.formgroup {
  width: 80%;
  height: auto;
  align-content: center;
  margin: auto;
  align-self: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.form-label {
  color: rgba(255, 228, 196, 0.767);
  font-size: 20px;
}
#black {
  background-color: black;
  width: 70%;
  min-width: 90%;
  border-color: rgba(222, 184, 135, 0.397);
  color: #f0f8ff;
  letter-spacing: 3px;
}
#email {
  background-color: black;
  width: 70%;
  min-width: 90%;
  border-color: rgba(222, 184, 135, 0.397);
  color: #f0f8ff;
  letter-spacing: 3px;
}
#logoNavBar {
  margin-top: 1%;
}
input[type="text"] {
  color: rgb(146, 146, 132);
}

.table-certificate {
  margin-left: 6%;
  margin-right: 6%;
}
.tb {
  background-color: rgb(40, 59, 77);
}
.disp {
  width: 88%;
  margin: auto;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  align-content: left;
  margin: auto;
  border-radius: 8px;

  background-color: rgb(23, 34, 44);

  box-shadow: 0 2.8px 2.2px rgba(4, 33, 56, 0.719),
    0 6.7px 5.3px rgba(4, 33, 56, 0.719),
    0 12.5px 10px rgba(4, 33, 56, 0.719) 0 22.3px 17.9px rgba(4, 33, 56, 0.719),
    0 41.8px 33.4px rgba(4, 33, 56, 0.719), 0 100px 80px rgba(4, 33, 56, 0.719);
}
.disp h1 {
  color: rgba(255, 228, 196, 0.756);
  letter-spacing: 3px;
  align-self: start;
  align-items: left;
}
.disp p {
  color: rgba(240, 248, 255, 0.865);
  font-size: 20px;
}
.tb {
  border-radius: 8px;
}
.d-btn {
  width: 40%;
  margin-left: 30%;
}

@media screen and (max-width: 900px) {
  .d-btn {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (max-width: 450px) {
  .headerContainer {
    padding-right: 7.5%;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.extra-info {
  display: none;
  line-height: 30px;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 50px;
}

.info:hover .extra-info {
  display: block;
}

.info {
  font-size: 20px;
  padding-left: 5px;
  width: 20px;
  border-radius: 15px;
}

.info:hover {
  background-color: white;
  padding: 0 0 0 5px;
  width: 315px;
  text-align: left !important;
}
td{
    word-break: break-word;
    word-wrap: break-word;
}

.custom-btn-width {
  width: auto;
  margin-top: -20px;
}

.certificate-page {
  background-color: rgb(40, 59, 77); /* Change this to your desired background color */
  height: 100%; /* Ensure the background color covers the full height */
}


.card-row{
    display: flex;
  }
body{
    font-family: 'Quicksand';
}
.img-card{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
  .card-name{
    padding-top: 15px;
    font-size: 20px;
    text-align: center;
    color: rgb(16, 154, 181);
    font-weight: 600;
  }
  .card-des{
    text-align: center;
    font-weight: 400;
  }
  .card-item{
    align-items: center;
    width: 25%;
    padding: 30px;
  }
  .card-item img{
    height: 250px;
    width: 250px;
    border-radius: 10px;
  }


  @media (max-width: 768px) {

  .card-item{
    width: 50%;
    height: auto;
  }
  .card-item img{
    width: 125px;
    height: 125px;
  }
    
    }





    /* ipad */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .card-item img{
            width: 150px;
            height: 150px;
        }


    }
/* .Aff-p {
	display: flex;
	justify-content: space-between;
	padding: 10px 30px 10px 30px;
	padding-right: 40px;
	padding-left: 40px;
} */

.aff-h1 {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 48px;
	margin-top: 30px;
	/* letter-spacing: -2px; */
}

.aff-h1-home {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 48px;
	margin-top: 30px;
	/* letter-spacing: -2px; */
}

.aff-p-1-container {
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}


.aff-p-1 {
	font-weight: 600;
	font-size: 18px;
}

.aff-hero-btn {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-size: 23px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	;
	color: #fff;
}


.aff-hero {
	display: flex;
	justify-content: space-around;
}

.aff-hero-left {
	justify-content: left;
	text-align: left;
}

.aff-hero-subheading {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 32px;
	margin-top: 30px;
}

.aff-hero-img {
	width: 450px;
	overflow: hidden;
}

.aff-hero-img img {
	width: 100%;
}

.aff-hero-subheading-2 {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 32px;
	margin-bottom: 20px;
}

/* .aff-hero-right{

} */

.aff-hero-right-img {
	width: 500px;
}
.aff-hero-right-img img {
	width: 100%;
}

.aff-unite{
	background-color: #ececec;
	padding-top: 10px;
	margin-top: 50px;
	margin-bottom: 50px;
}

.aff-unite-h1{
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 10px;
}

.aff-unite-subheading {
	font-family: anton;
	font-weight: 400;
	color: #1875d1;
	font-size: 30px;
	margin-bottom: 40px;
}

.space{
	height: 10px;
}


.aff-partners-and-affiliates {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 80px;
	margin-top: 50px;
}

.aff-partners-and-affiliates-logo-img-container {
	width: 200px;	
}

.aff-partners-and-affiliates-logo-img-container img{
	width: 100%;
}

.aff-partners-and-affiliates-heading{
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 10px;
}

.aff-partners-and-affiliates-img-container {
	width: 90vw;
}

.aff-partners-and-affiliates-img-container img{
	width: 100%;
}




.Aff-button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

.Aff-button:hover {
	background-color: rgb(0, 47, 66);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.Coll-button {
	width: 380px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 5px;
	font-size: 20px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

.Coll-button:hover {
	background-color: rgb(0, 47, 66);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.aff-youth-programs-container {
	display: flex;
	justify-content: space-around;
	margin-bottom: 50px;
}

.aff-youth-program-1 {
	width: 30vw;
	padding: 10px;
	border-radius: 15px;
	background-color: #ececec;
}

.aff-youth-program-heading {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 27px;
	margin-top: 30px;
	margin-bottom: 25px;
}

.aff-youth-program-1-bold-p {
	color: rgb(0, 120, 225);
	font-weight: 700;
	font-size: 20px;
	line-height: 25px;
}

.aff-youth-program-1-p {
	color: rgb(0, 120, 225);
	font-weight: 500;
	font-size: 18px;
}

.aff-youth-program-2 {
	width: 30vw;
	padding: 10px;
	border-radius: 15px;
	background-color: #ececec;
}

.aff-youth-program-2-p {
	color: rgb(0, 120, 225);
	font-weight: 500;
	font-size: 18px;
}

.aff-youth-program-3 {
	width: 30vw;
	padding: 10px;
	border-radius: 15px;
	background-color: #ececec;
}

.aff-youth-program-heading-3 {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 27px;
	margin-top: 30px;
	margin-bottom: 5px;
}

.aff-youth-program-3-big-text {
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 44px;
	margin-bottom: 30px;
}


.wasi-container {
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 10px;
	flex-wrap: wrap;
}




.wasi-2-btns-container {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 50px;
}



.wasi-2-btns-heading {
	font-family: anton;
	font-weight: 400;
	color: black;
	font-size: 45px;
	margin-top: 30px;
	margin-bottom: 15px;
}

.wasi-2-btns-btn {
	padding: 10px;
	padding-right: 25px;
	padding-left: 25px;
	border: none;
	font-size: 20px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	color: #fff;
}

.aff-wasi-p {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
}



.aff-impc-container {
	display: flex;
	flex-direction: column;
	margin: 50px 0;
	background-color: #ececec;
}

.aff-impc-heading {
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.aff-impc-heading-blue {
	font-family: anton;
	font-weight: 400;
	color: rgb(0, 120, 225);
	font-size: 48px;
	margin-top: 50px;
	margin-bottom: 20px;
}

.aff-impc-ahp {	
	font-weight: 600;
	color: rgb(0, 120, 225);
	font-size: 25px;
	/* margin-top: 30px; */
	margin-bottom: 10px;
}

.aff-impc-subcontainer{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.aff-impc-subcontainer-left{
	width: 340px;
	background-color: white;
	padding: 25px 0px;
	padding-left: 40px;
	padding-right: 40px;
}

.aff-impc-subcontainer-left-heading {
	font-weight: 700;
}

.aff-impc-subcontainer-left-p {
	font-size: 16px;
	color: rgb(0, 120, 225);
}

.aff-impc-subcontainer-right {
	width: 340px;
	margin: 20px;
	padding-left: 20px;
	background-color: white;
}

.aff-impc-subcontainer-right img{
	width: 100%;
}

.aff-impc-p {
	width: 80vw;
	font-weight: 600;
	font-size: 20px;
	align-self: center;
	margin-top: 10px;
	margin-bottom: 40px;
}

.aff-impc-btn {
	width: 280px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-size: 20px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	color: #fff;
	align-self: center;
	justify-self: center;
	margin-bottom: 30px;
}


.wtayswim-p {
	font-size: 20px;
	font-weight: 600;
	color: rgb(0, 120, 225);
}

.wtayswimi-btn{
	width: 280px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-size: 20px;
	font-weight: 600;
	border-radius: 6px;
	background-color: rgb(29, 29, 29);
	color: #fff;
	align-self: center;
	justify-self: center;
	margin-bottom: 30px;
}






.aff-new-prog {
	display: flex;
	padding-right: 35px;
	padding-left: 35px;
	justify-content: space-between;
}

.head-af {
	font-size: 35px;
	text-align: center;
	padding-top: 15px;
	font-family: "Quicksand", sans-serif;
}
.af-content {
	padding-left: 160px;
	padding-right: 160px;
	font-size: 25px;
	text-align: center;
	font-weight: 900;
}
.af-content a {
	text-decoration: none;
}

.school-form {
	margin: 40px;
	padding: 40px;
	border: 0.6px dotted black;
}

.school-form-head {
	font-size: 30px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	text-align: left;
}

.school-form-items {
	display: flex;
	flex-wrap: wrap;
}

.additional-info-p {
	width: 100%;
	text-align: start;
	font-size: 25px;
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.additional-info-p p {
	width: 100%;
	margin-bottom: 0;
	font-weight: 500;
}

.additional-info-p span {
	margin-bottom: 0;
	font-weight: 900;
}

.additional-info-div {
	margin-top: 20px;
	width: 100%;
	display: flex;
	/* align-items: start;
	justify-content: start; */
	text-align: start;
	margin-left: 10px;
}

.left-text-payment {
	width: 70%;
	font-size: 21px;
}

.ltp-1 {
	font-weight: 900;
	margin-bottom: 0px;
}

.ltp-2 {
	font-weight: 900;
	color: #1875d1;
	font-size: 25px;
}
.ltp-3 {
	font-weight: 900;
}

.right-img-qr {
	width: 30%;
}

.right-img-qr img {
	width: 100%;
}

.razorpay_pay {
	display: flex;
	flex-direction: column;
}

.rp-1 {
	margin-bottom: 0;
	font-size: 25px;
}
.rp-2 {
	margin-bottom: 0;
	font-size: 25px;
}

.razorpay-btn {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.rp-3 {
	font-weight: 900;
	font-size: 28px;
	margin-right: 10px;
	margin-bottom: 0;
}

.razorpay-btn button {
	width: 150px;
	padding: 0;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-size: 20px;
	font-weight: 700;
	border-radius: 15px;
	text-align: center;
	background-color: rgb(0, 47, 66);
	color: #fff;
}

.razorpay-btn button:hover {
	background-color: rgb(0, 120, 225);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.school-items-form {
	width: 90%;
	padding: 10px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	position: relative;
	text-align: left;
	align-items: center;
	justify-content: space-between;
	display: flex;
	font-size: 20px;
}
.school-items-form input {
	position: absolute;
	border: 1px solid rgb(26, 26, 26);
	right: 40px;
	width: 45%;
}

.up-dec-button {
	width: 10%;
	padding: 5px 15px 5px 15px;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-weight: 700;
	border-radius: 15px;
	text-align: center;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

.up-dec-button:hover {
	background-color: rgb(0, 47, 66);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.pay-btn {
	padding: 5px 15px 5px 15px;
	/* padding-right: 15px;
    padding-left: 15px; */
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 47, 66);
	color: #fff;
}

.pay-btn:hover {
	background-color: rgb(0, 120, 225);
	transition-duration: 450ms;
	transition-delay: 75ms;
}

.btn-common {
	margin: 10px;
	display: flex;
	justify-content: space-between;
}
.last_buttons {
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
}

.error {
	color: red;
	background: pink;
	border: 1px solid red;
	border-radius: 4px;
	padding: 8px;
	margin: 10px 0;
}
.after-submit {
	width: 50%;
	display: none;
	border: 0.7px solid black;
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 20px;
}
.after-submit button {
	background-color: rgb(0, 120, 225);
	border: none;
	border-radius: 20px;
	color: white;
	padding: 5px 10px 5px 10px;
	font-weight: 700;
}

.why-affiliate-container {
	width: 99vw;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 20px;
	grid-gap: 20px;
	gap: 20px;
	justify-items: center;
	margin-top: 50px;
	margin-bottom: 50px;
	grid-row-gap: 50px;
}
.aff-benifit-container {
	width: 220px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	border: 1px solid black;
	border-radius: 20px;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	height: 400px; /* Set a fixed height of 400px */
  box-sizing: border-box;
	/* text-align: start; */
}
.aff-benifit-container img {
	width: 130px;
	margin-bottom: 10px;
	/* text-align: center; */
}
.aff-benifit-container-title {
	font-weight: bolder;
	color: #1875d1;
	font-size: 18px;
	/* margin-top: 10px; */
	font-family: "Quicksand";
	margin-bottom: 20px;
}

.aff-benifit-container-button {
	border: 1px solid black;
	border-radius: 50%;
	color: white;
	background-color: black;
}

.aff-benifit-container-p {
	font-size: 15px;
	font-weight: 600;
	margin-top: 20px;
	/* text-align: start; */
}

.perk-benefit {
	padding-right: 30px;
	padding-left: 30px;
	padding-bottom: 30px;
	/* display: flex; */
	justify-content: space-between;
}
.perk-benefit span {
	font-size: 30px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	color: rgb(35, 116, 162);
}
.perk-benefit button {
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(47, 136, 214);
	color: #fff;
}
.com-perk-eli {
	padding-right: 30px;
	padding-left: 30px;
}
.com-perk-eli p {
	text-align: left;
	font-weight: 600;
}
.com-perk-eli li {
	text-align: left;
	font-weight: 600;
}
.perk-head {
	color: rgb(17, 128, 224);
	font-weight: 600;
}
.perk-head1 {
	color: rgb(0, 0, 0);
	font-weight: 600;
}
.eli-criteria {
	padding: 30px;
	font-size: 30px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	color: rgb(40, 148, 86);
	text-align: left;
}
.eli-head {
	color: rgb(27, 161, 85);
	font-weight: 600;
}
.last_check {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border: 0.6px dotted black;
	margin-top: 10px;
	border-radius: 20px;
}
.last_check_box input {
	padding: 40px;
}
.last_check div {
	padding: 10px;
}
.last_check p {
	text-align: justify;
	padding: 10px;
	font-weight: 600;
}

.collapse-buttons {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.imcc-btn {
	margin: 20px;
}

.icc-main {
	text-align: left;
	width: 70vw;
}

.imcc-main-content p span {
	font-weight: 900;
}

@media (max-width: 768px) {

	.why-affiliate-container {
		max-width: 99vw;
		overflow: hidden;
		margin-top: 20px;
		grid-row-gap: 10px;
		justify-content: space-around;
		grid-template-columns: repeat(2, 1fr);
	}

	.aff-partners-and-affiliates-img-container {
		display: none;
	}

	.aff-h1 {
		font-size: 38px;
	}

	.aff-unite-subheading {
		font-size: 24px;
	}

	.aff-hero {
		display: flex;
		max-width: 99vw;
		/* justify-content: space-around; */
		justify-content: center;
		flex-wrap: wrap;
		/* max-width: 100vw; */
		/* overflow: hidden; */
	}
	
	.aff-hero-left {
		justify-content: center;
		text-align: center;
		display: flex;
		flex-direction: column;
	}
	
	.aff-hero-subheading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 25px;
		margin-top: 30px;
	}

	.aff-unite-h1{
		font-size: 36px;
	}
	
	.aff-hero-img {
		align-self: center;
		width: 300px;
		overflow: hidden;
	}
	
	.aff-hero-img img {
		width: 100%;
	}

	.aff-hero-btn {
		width: 300px;
		align-self: center;
		padding: 5px;
		padding-right: 5px;
		padding-left: 5px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
	}
	
	.aff-hero-subheading-2 {
		font-family: anton;
		font-weight: 400;
		color: #1875d1;
		font-size: 26px;
		margin-bottom: 20px;
	}
	
	.aff-hero-right-img {
		width: 300px;
	}
	.aff-hero-right-img img {
		width: 100%;
	}


	.aff-youth-programs-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: column;
		margin-bottom: 50px;
	}
	
	.aff-youth-program-1 {
		width: 90vw;
		margin-bottom: 20px;
	}
	
	.aff-youth-program-heading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 27px;
		margin-top: 30px;
		margin-bottom: 25px;
	}

	.aff-partners-and-affiliates-heading{
		font-size: 38px;
	}
	
	.aff-youth-program-1-bold-p {
		color: rgb(0, 120, 225);
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;
	}
	
	.aff-youth-program-1-p {
		color: rgb(0, 120, 225);
		font-weight: 500;
		font-size: 18px;
	}
	
	.aff-youth-program-2 {
		width: 90vw;
		margin-bottom: 20px;
	}
	
	.aff-youth-program-2-p {
		color: rgb(0, 120, 225);
		font-weight: 500;
		font-size: 18px;
	}
	
	.aff-youth-program-3 {
		width: 90vw;
	}
	
	.aff-youth-program-heading-3 {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 27px;
		margin-top: 30px;
		margin-bottom: 5px;
	}

	.aff-h1-home {
		font-family: anton;
		font-weight: 400;
		color: #1875d1;
		font-size: 30px;
		margin-top: 30px;
		/* letter-spacing: -2px; */
	}
	
	.aff-youth-program-3-big-text {
		font-family: anton;
		font-weight: 400;
		color: rgb(0, 120, 225);
		font-size: 44px;
		margin-bottom: 30px;
	}

	.wasi-container {
		font-family: anton;
		font-weight: 400;
		color: rgb(0, 120, 225);
		font-size: 30px;
		margin-top: 30px;
		margin-bottom: 10px;
		flex-wrap: wrap;
	}
	
	.wasi-2-btns-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 50px;
	}
	
	.wasi-2-btns-heading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 25px;
		margin-top: 30px;
		margin-bottom: 15px;
	}
	
	.wasi-2-btns-btn {
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
	}
	
	.aff-wasi-p {
		padding-left: 40px;
		padding-right: 40px;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	}

	.Coll-button {
		width: 300px;
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-weight: 700;
		border-radius: 5px;
		font-size: 16px;
		background-color: rgb(0, 120, 225);
		color: #fff;
	}
	
	.Coll-button:hover {
		background-color: rgb(0, 47, 66);
		transition-duration: 450ms;
		transition-delay: 75ms;
	}

	
	.aff-impc-container {
		display: flex;
		flex-direction: column;
		margin: 70px 0;
	}
	
	.aff-impc-heading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 34px;
		margin-top: 30px;
		margin-bottom: 10px;
	}
	
	.aff-impc-heading-blue {
		font-family: anton;
		font-weight: 400;
		color: rgb(0, 120, 225);
		font-size: 34px;
		margin-top: 70px;
		margin-bottom: 20px;
	}
	
	.aff-impc-ahp {	
		font-weight: 600;
		color: rgb(0, 120, 225);
		font-size: 20px;
		/* margin-top: 30px; */
		margin-bottom: 10px;
	}
	
	.aff-impc-subcontainer{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
	}
	
	.aff-impc-subcontainer-left{
		width: 340px;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		height: 130px;
		padding-right: 5px;
		padding: 0px;
	}
	
	.aff-impc-subcontainer-left-heading {
		font-weight: 700;
	}
	
	.aff-impc-subcontainer-left-p {
		font-size: 16px;
		color: rgb(0, 120, 225);
	}
	
	.aff-impc-subcontainer-right {
		width: 340px;
		padding-left: 20px;
	}
	
	.aff-impc-subcontainer-right img{
		width: 100%;
	}
	
	.aff-impc-p {
		width: 80vw;
		font-weight: 600;
		font-size: 20px;
		align-self: center;
		margin-top: 40px;
		margin-bottom: 40px;
	}
	
	.aff-impc-btn {
		width: 300px;
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
		align-self: center;
		justify-self: center;
		margin-bottom: 30px;
	}



	.wtayswim-p {
		font-size: 20px;
		padding: 15px;
		font-weight: 600;
		color: rgb(0, 120, 225);
	}
	
	.wtayswimi-btn{
		width: 300px;
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 15px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(0, 120, 225);
		color: #fff;
		align-self: center;
		justify-self: center;
		margin-bottom: 30px;
	}
	


	.additional-info-p {
		font-size: 20px;
	}
	.additional-info-div {
		flex-direction: column;
	}
	.right-img-qr {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.right-img-qr img {
		width: 80%;
	}
	.razorpay_pay {
		display: flex;
		flex-direction: column;
		text-align: left;
	}
	.rp-1 {
		margin-bottom: 0;
		font-size: 20px;
	}
	.rp-2 {
		margin-bottom: 0;
		font-size: 20px;
	}
	.rp-3 {
		font-weight: 900;
		font-size: 20px;
		margin-right: 10px;
		margin-bottom: 0;
	}
	.aff-benifit-container {
		width: 90%;
		height: auto;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
	}
	.aff-benifit-container img {
		width: 90px;
		/* margin-bottom: 10px; */
	}
	.aff-benifit-container-title {
		font-weight: bolder;
		color: #1875d1;
		font-size: 15px;
		margin-top: 10px;
		font-family: "Quicksand";
		margin-bottom: 15px;
	}
	.aff-benifit-container-p {
		font-size: 12px;
		font-weight: 600;
		/* text-align: start; */
	}
	.school-items-form {
		width: 100%;
		font-size: 10px;
		padding: 7px;
	}
	.school-form {
		margin: 20px;
		padding: 20px;
	}

	.up-dec-button {
		font-size: 12px;
	}
	.pay-btn {
		font-size: 12px;
	}
	.school-items-form input {
		right: 0px;
		width: 40%;
	}
	.up-dec-button {
		width: 30%;
	}
	.last_buttons {
		padding-top: 13px;
	}
	.aff-new-prog {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
	}
	.perk-benefit span {
		font-size: 25px;
	}
	.perk-benefit button {
		font-size: 20px;
		width: 90%;
	}
	.last_check {
		padding: 5px;
	}
	.last_check div {
		padding: 3px;
	}
	.last_check p {
		font-size: 8px;
		padding: 4px;
	}
	.Aff-p {
		font-size: 15px;
	}
	.Aff-button {
		/* padding-right: 5px;
      padding-left: 5px;
      font-size: 15px; */
		width: 320px;
	}
	.af-content {
		padding-left: 40px;
		padding-right: 40px;
		font-size: 15px;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.Aff-button {
		font-size: 15px;
	}

	.aff-hero-btn {
		padding: 5px;
		padding-right: 15px;
		padding-left: 15px;
		border: none;
		font-size: 23px;
		font-weight: 600;
		border-radius: 6px;
		background-color: rgb(29, 29, 29);
		color: #fff;
	}
	
	
	.aff-hero {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	
	.aff-hero-left {
		justify-content: center;
		text-align: center;
	}
	
	.aff-hero-subheading {
		font-family: anton;
		font-weight: 400;
		color: black;
		font-size: 32px;
		margin-top: 30px;
	}
	
	.aff-hero-img {
		width: 450px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	
	.aff-hero-img img {
		width: 100%;
		align-self: center	;
	}
	
	.aff-hero-subheading-2 {
		font-family: anton;
		font-weight: 400;
		color: #1875d1;
		font-size: 32px;
		margin-bottom: 20px;
	}
	
	/* .aff-hero-right{
	
	} */
	
	.aff-hero-right-img {
		width: 400px;
	}
	.aff-hero-right-img img {
		width: 100%;
	}

	.why-affiliate-container {
		max-width: 100%;
		margin-top: 20px;
		grid-row-gap: 20px;
		grid-template-columns: repeat(4, 1fr);
	}
	.aff-benifit-container {
		width: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
	}
	.aff-benifit-container img {
		width: 90px;
	}
	.aff-benifit-container-title {
		font-weight: bolder;
		color: #1875d1;
		font-size: 15px;
		margin-top: 10px;
		font-family: "Quicksand";
		margin-bottom: 15px;
	}
	.aff-benifit-container-p {
		font-size: 12px;
		font-weight: 600;
		/* text-align: start; */
	}

	.school-items-form {
		width: 100%;
		font-size: 13px;
		padding: 10px;
	}

	.school-items-form input {
		right: 0px;
		width: 60%;
	}

	.up-dec-button {
		width: 30%;
	}
}

.main-container {
	max-width: 99vw;
  margin: 20px;
}

.Aff-button {
	width: 300px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
  font-size: 20px;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

.Aff-button:hover {
  background-color: rgb(0, 47, 66);
  transition-duration: 450ms;
  transition-delay: 75ms;
}

.content {
	/* border: 1px solid rgb(128, 123, 123); */
	padding: 0.5rem;
	/* border-radius: 5px; */
  background-image: none;
}

.content-parent {
	height: 0px;
	overflow: hidden;
	transition: height ease 0.9s;
}

.content-show {
	height: 80vh;
}

@media (max-width: 768px) {
	/* .content-parent {
    height: auto !important; 
    max-height: 200px !important; 
    overflow-y: auto !important;
  } */
}
.log-nav {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	background-color: rgb(37, 37, 36);
}
.log-nav1 {
	display: flex;
	padding: 5px;
	justify-content: center;
	align-items: center;
}
.log-nav1 img {
	/* display: flex; */
	width: 50px;
	height: 50px;
	border-radius: 50%;
	padding: 3px;
	/* align-items: center;
    justify-content: center; */
}
.log-nav1 h4 {
	font-size: 30px;
	text-align: center;
	color: #fff;
}
.log-nav2 button {
	width: 100%;
	padding: 10px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

/* modal */
.modal {
	position: fixed;
	top: 0%;
	left: 30%;
	width: 50%;
	height: 100%;
	/* background-color: rgba(0, 0, 0, 0.5); */
	display: flex;
	align-items: center;
	justify-content: center;
}
.model_div {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.model_div button {
	border: none;
	background: none;
	/* margin-left: 20px; */
}
.model_div_mob {
	display: none;
}

.modal-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 4px;
}

h2 {
	margin-top: 0;
}

form {
	display: flex;
	flex-direction: column;
}

.form-row {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	width: 100%;
}

.form-row label {
	/* display: table;
    align-items: center; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.form-row label span {
	font-size: 20px;
	margin-right: 10px;
}

input {
	padding: 5px;
	border-radius: 4px;
	border: 1px solid #ccc;
	/* display: table-column; */
	width: 90%;
}

.submit_event {
	padding: 10px 20px;
	background-color: #4caf50;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	width: 90%;
}
.submit-div {
	padding-top: 10px;
	align-items: center;
}

.enroll-main {
	display: flex;
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	padding: 15px;
	background-color: rgb(135, 181, 64);
	border-radius: 5px;
	align-items: center;
}
.enroll-div {
	display: flex;
	width: 25%;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	margin: auto;
	align-items: center;
	color: white;
}
.enroll-status {
	padding-bottom: 20px;
	font-size: 16px;
	font-weight: 600;
}
.enroll-free {
	font-size: 35px;
	font-weight: 700;
	color: white;
}
.enroll-main button {
	padding: 5px;
	padding-right: 10px;
	padding-left: 10px;
	border: none;
	font-weight: 700;
	border-radius: 15px;
	background-color: black;
	margin-top: 10px;
	color: #fff;
	font-size: 17px;
	width: 100%;
}
.com-perk-eli2 {
	padding-right: 40px;
	padding-left: 40px;
	text-align: left;
}
.com-perk-eli2 p {
	font-weight: 600;
	text-align: justify;
}
.Be-india {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 23px;
	text-align: justify;
}
.perk-head {
	font-size: 20px;
	font-weight: 600;
}

.info-strip {
	margin-top: 20px;
}
.overview {
	padding-right: 30px;
	padding-left: 30px;
	padding-bottom: 30px;
	padding-top: 25px;
	border-bottom: 3px solid black;
}
.overview h1 {
	font-family: anton;
	padding: 20px;
}
.overview p {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 17px;
	font-weight: 600;
}
.high-head {
	font-family: anton;
	padding: 20px;
	padding-bottom: 10px;
}
.pro-heig {
	display: flex;
	flex-wrap: wrap;
}
.pro-item {
	padding: 20px;
	width: 33%;
}
.pro-head {
	text-align: center;
	font-size: 30px;
	font-weight: 600;
}
.pro-des {
	text-align: center;
	font-size: 15px;
	font-weight: 600;
}
.am-go {
	display: flex;
	justify-content: space-evenly;
	padding-bottom: 20px;
	border-bottom: 2px solid black;
}
.key-ler {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding: 30px;
	padding-bottom: 40px;
	border-bottom: 2px solid black;
}
.key-item {
	padding-top: 10px;
	display: flex;
	margin: 10px;
	width: 28%;
	background-color: rgb(5, 141, 214);
	align-items: center;
	justify-content: center;
	border: 2px solid rgb(5, 141, 214);
	border-radius: 5px;
}
.key-item p {
	font-size: 24px;
	color: white;
	font-weight: 600;
}
.sub-mod {
	font-weight: 600;
	font-size: 18px;
	margin-top: -20px;
	padding-bottom: 20px;
	text-transform: uppercase;
}
.intro-am {
	text-align: left;
	padding-right: 40px;
	padding-left: 40px;
	font-size: 25px;
	color: rgb(28, 139, 229);
	font-weight: 600;
}
.mod_item_div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-right: 40px;
	padding-left: 40px;
}
.mod-item {
	-webkit-user-select: none;
	        user-select: none;
	cursor: pointer;
	text-align: left;

	/* padding-right: 40px;
    padding-left: 40px; */
	padding-bottom: 5px;
	border-bottom: 1px solid black;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mod-item1 {
	width: 100%;
	text-align: justify;
	font-size: 17px;
	font-weight: 550;
	/* max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 1s ease-in-out, opacity 1s ease-in-out; */
}
/* .mod-item1 .show{
    max-height: 1000px !important; 
    opacity: 1 !important;
  } */
.mod-item span {
	font-size: 22px;
	font-weight: 700;
}
.mod_item_div1 {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}
.t-main {
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: 30px;
}
.t-note {
	margin: 0;
	text-align: left;
	font-style: italic;
	font-weight: 600;
}
.mod-item_new {
	text-align: left;

	/* padding-right: 40px;
  padding-left: 40px; */
	padding-top: 5px;
	border-top: 1px solid black;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mod-item_new span {
	font-size: 22px;
	font-weight: 700;
}

.main-cer {
	display: flex;
}
.right-cer {
	width: 50%;
	padding: 40px;
}
.left-cer {
	padding: 40px;
	width: 50%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.left-cer p {
	text-align: left;
	font-size: 18px;
	font-weight: 600;
}
.last-strip {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	padding-right: 40px;
	padding-left: 40px;
	margin-bottom: 20px;
}
.last-strip span {
	font-size: 28px;
}
.nor-but {
	margin-right: 20px;
	margin-left: 20px;
	font-size: 20px;
	background-color: rgb(0, 120, 225);
	color: white;
	border: none;
	font-weight: 600;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 5px;
}
.big-enroll {
	font-size: 30px;
	background-color: rgb(0, 120, 225);
	color: white;
	border: none;
	font-weight: 600;
	padding-right: 30px;
	padding-left: 30px;
	border-radius: 8px;
	margin-left: 3px;
}

.main-button {
	background-color: rgb(0, 120, 225);
	border: 0;
	padding: 7px 25px;
	/* font-size: 20px; */
	border-radius: 3px;
	cursor: pointer;
	color: white;
	position: relative;
	border-radius: 10px;
}
.main-button:after {
	background-color: rgb(0, 120, 225);
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 3px;
	animation-name: blink;
	animation-duration: 0.5s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes blink {
	0% {
		transform: scale3d(1, 1, 1);
		opacity: 0.8;
	}
	100% {
		transform: scale3d(1.1, 1.3, 1.1);
		opacity: 0;
	}
}

@media (max-width: 768px) {
	.log-nav1 h4 {
		font-size: 12px;
		margin-top: 4px;
	}
	.log-nav1 img {
		height: 30px;
		width: 30px;
	}
	.log-nav2 {
		padding: 10px;
	}
	.log-nav2 button {
		padding: 5px;
	}
	.modal {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.enroll-main {
		padding: 5px;
		width: 93%;
	}
	.enroll-main button {
		margin-top: -8px;
		font-size: 12px;
		width: 100%;
	}
	.enroll-free {
		font-synthesis: 10px;
	}
	.Be-india {
		font-size: 20px;
	}
	.info-strip img {
		content: url("/static/media/IYFA mob banner.42225973.webp");
	}
	.overview h1 {
		font-size: 30px;
	}
	.overview p {
		padding-right: 20px;
		padding-left: 20px;
	}
	.pro-item {
		width: 50%;
	}
	.pro-head {
		font-size: 20px;
	}
	.pro-des {
		font-size: 15px;
	}
	.key-ler {
		padding: 10px;
	}
	.key-item {
		width: 40%;
	}
	.key-item p {
		font-size: 15px;
	}
	.mod-item span {
		font-size: 18px;
	}
	.mod-item_new span {
		font-size: 18px;
	}
	.sub-mod {
		font-size: 14px;
	}
	.main-cer {
		flex-direction: column-reverse;
	}
	.right-cer {
		width: 100%;
	}
	.left-cer {
		width: 100%;
		padding-top: 0px;
	}
	.last-strip {
		justify-content: space-evenly;
		padding-left: 10px;
		padding-right: 10px;
	}
	.last-strip span {
		font-size: 15px;
	}
	.nor-but {
		font-size: 9px;
		margin-right: 2px;
		margin-left: 2px;
		padding-right: 10px;
		padding-left: 10px;
	}
	.big-enroll {
		font-size: 10px;
		padding-right: 20px;
		padding-left: 20px;
		margin-bottom: 3px;
	}
	.movile {
		display: flex;
		flex-direction: column;
	}
	.form-row label {
		display: flex;
		flex-direction: column;
		width: 95%;
		justify-content: center;
		align-self: center;
	}
	.form-row label span {
		font-size: 14px;
	}
	.form-row label input {
		width: 100%;
	}
	.model_div_mob {
		display: flex;
	}
	.submit_event {
		width: 100%;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.enroll-main {
		padding: 5px;
	}
	.enroll-main button {
		font-size: 13px;
		width: 120%;
	}
	.key-item p {
		font-size: 23px;
	}
	.info-strip {
		height: 150px;
	}
}

.head-am2 {
	font-size: 45px;
	text-align: center;
	font-family: "anton", sans-serif;
	color: rgb(43, 143, 66);
}
.part_d1 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 30px;
	padding-right: 30px;
	align-items: center;
	padding-top: 15px;
}
.part_d1 p {
	max-width: 99%;
	overflow: hidden;
	font-size: 33px;
	color: rgb(61, 133, 214);
}
.head-am2_ylp {
	font-size: 48px;
	text-align: start;
	font-family: "anton", sans-serif;
	color: rgb(34, 96, 205);
}

.part_d1_ylp p {
	font-size: 33px;
	color: white;
}
.con-mind2 {
  /* margin-left: 20px; */
	padding-left: 30px;
	padding-right: 30px;
	text-align: left;
  margin-right: 20px;
	font-size: 22px;
	padding-bottom: 5px;
}
.con-mind2_ylp {
	text-align: left;
  margin-left: 50px;
  margin-right: 20px;
	font-size: 22px;
	padding-bottom: 5px;
}
.part_d1_ylp {
	width: 100%;
	max-width: 95vw;
	display: flex;
	flex-direction: row;
	/* justify-content: space-around; */
	align-items: start;
	padding-top: 15px;
	padding-left: 50px;
}
.forest-am2 {
	display: flex;
	padding-bottom: 20px;
	flex-direction: column;
	opacity: 0.02px;
}
.forest_img {
	background-image: url(/static/media/mn.98f14a56.webp);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 700px;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
/* .ylp_img {
	background-image: url("./images/ylp/YLP\ image\ 1\ desk.webp");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 700px;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
} */
.forest_img button {
	margin-top: 430px;
	background-color: black;
	color: white;
	border-bottom: 6px solid white;
	font-size: 46px;
	font-weight: 600;
}
.forestam {
	width: 100vw;
	align-items: center;
	padding: 10px 0 10px 0;
}
.forestam_ylp {
	align-items: center;
	padding: 40px 0 40px 0;
}
.forestam img {
	width: 100%;
	height: auto;
}
.forestam_ylp img {
	width: 100%;
	height: auto;
}
.ylp_line {
	text-align: center;
	font-weight: 900;
	margin: 0 10px 0 10px;
}
.ending-ylp {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 30px;
	margin: 20px 0 20px 0;
}
.proover {
	font-family: anton;
	padding: 20px;
	color: rgb(0, 120, 225);
	/* border-top: 1.5px solid black; */
}
.info-strip1 {
	padding-top: 25px;
	margin-bottom: 25px;
}

.pro-strip {
	display: flex;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 15px;
	justify-content: space-evenly;
}
.img-item img {
	height: 100px;
}
.grey-text {
	margin-left: 12px;
	margin-right: 12px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	background-color: rgb(240, 240, 240);
	margin-bottom: 0px;
}
.text1 {
	margin-top: 30px;
	padding-left: 40px;
	padding-right: 40px;
	font-size: 40px;
	font-weight: 650;
	text-align: center;
}
.text2 {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 98px;
	color: rgb(51, 168, 82);
	font-weight: 300;
	font-family: "Anton", sans-serif;
	text-align: center;
}
.text3 {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 38px;
	font-weight: 650;
	margin-bottom: 40px;
	text-align: center;
}
.prakriti {
	margin-top: 20px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.img-item img {
		height: 75px;
	}
	.text1 {
		font-size: 30px;
	}
	.text2 {
		font-size: 70px;
	}
	.text3 {
		font-size: 27px;
	}
}

@media (max-width: 768px) {
	.head-am2_ylp {
		font-size: 40px;
	}
	.pro-strip {
		display: flex;
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 20px;
		margin-bottom: 5px;
	}
	.img-item img {
		height: 30px;
	}
	.con-mind2_ylp {
		margin-left: 20px;
		font-size: 22px;
	}
	.part_d1_ylp {
		margin-left: 20px;
		padding-left: 0px;
	}
	.forest-am2 {
		display: flex;
		flex-direction: column;
		opacity: 0.02px;
	}
	.grey-text {
		margin-left: 2px;
		margin-right: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 15px;
		text-align: justify;
		margin-bottom: 0px;
	}
	.text1 {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 18px;
		font-weight: 500;
	}
	.text2 {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 40px;
		color: rgb(51, 168, 82);
		font-weight: 300;
		font-family: "Anton", sans-serif;
	}
	.text3 {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 40px;
	}

	.prakriti {
		content: url(/static/media/mob.45816e75.webp);
		width: 100%;
		height: auto;
	}
}

@media (max-width: 768px) {
	.forest_img {
		height: 250px;
	}
	/* .ylp_img {
		height: 250px;
	} */
	.forest_img button {
		margin-top: 125px;
		font-size: 20px;
	}
	.head-am2 {
		font-size: 20px;
		text-align: left;
	}
	.part_d1 {
		padding-left: 10px;
		padding-right: 10px;
	}
	.part_d1 p {
		font-size: 16px;
	}
	.con-mind2 {
		font-size: 12px;
    /* margin-left: 10px;
    margin-right: 10px; */
		padding-left: 10px;
		padding-right: 10px;
	}
	.proover {
		padding-bottom: 0;
	}
	.forestam img {
		width: 100%;
	}
	.forestam_ylp img {
		width: 100%;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.forest_img {
		height: 500px;
	}
	.forestam_ylp img {
		width: 100%;
		content: url("/static/media/YLP image 2 (mobi).af197db3.png");
	}
	/* .ylp_img {
		height: 500px;
	} */
	.forest_img button {
		margin-top: 300px;
		font-size: 30px;
	}
	.head-am2 {
		font-size: 30px;
	}
	.part_d1 p {
		font-size: 20px;
	}
	.con-mind2 {
		font-size: 14px;
	}
}

.iyfa-head{
    font-size: 40px;
    font-family: anton;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 0px;
}
.iya-next{
    font-size: 22px;
    text-align: left;
    padding-left: 40px;
    margin-top: -10px;
}
.iyfa-top{
    text-align: left;
    padding-right: 40px;
    padding-left: 40px;
    font-size: 25px;
    color: rgb(8, 139, 210);
    text-decoration: underline;
    font-weight: 600;
}
.am-iyfa{
    display: flex;
    align-items: flex-start;
    padding-left: 40px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.am-iyfa p{
    width: 95%;
    padding-bottom: 30px;
    border-bottom: 1.5px solid black;
}
.sub-head{
    font-size: 25px;
    font-weight: 700;
    text-align: left;
    padding-left: 40px;
    font-weight: 700;
}
.am-cont{
    padding-left: 40px;
    padding-right: 40px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.what-eco{
    padding-left: 40px;
    text-align: left;
    font-size: 17px;
    color: rgb(11, 158, 237);
    font-weight: 600;
}
.mod-vid{
    margin-top: -15px;
    padding-left: 40px;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
}
.mod-vid span{
    font-weight: 600;
}
.mod-vid a{
    text-decoration: none;
    color: black;
}
.btn-div{
    padding-left: 10px;
    text-align: left;
    width: 95%;
    padding-bottom: 20px;
    margin: 0 auto;
    border-bottom: 1.5px solid black;
  
}
.btn-div button{
    color: white;
    background-color: rgb(66, 128, 222);
    border: none;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 600;
    margin-right: 3px;
}
.sub-2{
    padding-top: 20px;
}
.t-main{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .t-note{
    font-weight: 600;
    margin: 0;
    text-align: left;
    font-style: italic;
  }
  .b-bottom{
    padding-bottom: 13px;
  }
  .rzp{
    display: flex;
    justify-content:flex-start;
    /* align-items: center; */
    width: 95%;
    margin: 0 auto;
    border-top: 1.5px solid black;
    padding-top: 37px;
    /* margin-bottom: 20px; */
  }
  .rzp button{
    font-size: 45px;
    background-color: rgb(184, 214, 56);
    border: none;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    font-family: anton;
  }
  .rzp img{
    height: 40px;
    width: auto;
    margin-left: 12px;
    position: relative;
    align-self:flex-end;
  }
  .main-mod{
    padding: 40px;
    background-color: rgb(242, 248, 253);
}
.main-mod p{
    text-align: left;
    font-weight: 600;
}

.school-form{
  margin: 40px;
  padding: 40px;
  border: 0.5px solid black;
}
.school-form-head{
  font-size: 30px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}
.school-form-items{
  display: flex;
  flex-wrap: wrap;
}
.school-items{
  display: flex;
  width: 100%;
  padding: 10px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  justify-content: space-between;
  position: relative;
}
.school-items input{
  position: absolute;
  right: 40px;
  width: 70%;
}
.qr-div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rpqr{
    height: 400px;
    width: auto;
    padding-bottom: 20px;
}
.sub-btn button{
    margin-top: 20px;
    color: white;
    background-color: rgb(66, 128, 222);
    border: none;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 600;
    font-size: 22px;
}
.bold{
    color: rgb(27, 27, 182);
}
.small{
    font-size: 10px;
    padding-left: 10px;
}
.sub-btn-end button{
    background-color: rgb(16, 216, 16);
    border: none;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 600;
    font-size: 22px;
    margin-top: 20px;
    color: white;
}
.hyperlinkhere{
    color: blue;

}
.qr-div1{
    display: none;
}
@media (max-width: 768px){
    .iyfa-head{
        font-size: 35px;
    }
    .iya-next{
        font-size: 18px;
    }
    .iyfa-top{
        font-size: 22px;
    }
    .sub-head{
        font-size: 22px;
    }
    .mod-vid{
        font-size: 15px;
    }
    .btn-div{
        padding-left: 30px;
    }
    .am-cont{
        text-align: justify;
    }
    .rzp{
        padding-top: 20px;
        padding-left: 20px;
    }
    .rzp button{
        font-size: 18px;
    }
    .rzp img{
        height: 15px;
    }
    .school-items{
        font-size: 10px;
        padding: 10px;
      }
      .school-form{
        margin: 0px;
        padding: 20px;
      }
      .school-items input{
        right: 0px;
        width: 50%;
      }
      .qr-div{
          display: none;
      }
      .qr-div1{
          display: flex;
          align-items: center;
          justify-content: center;
          
      }
      .small{
        padding-left: 6px;
      }
      .main-mod p{
          text-align: justify;
      }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .school-items{
      font-size: 13px;
      padding: 10px;
    }
    .school-items input{
      right: 0px;
      width: 100%;
    }
  }
.ylpa-overview-container {
	margin-left: 12px;
	margin-right: 12px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-size: 20px;
	/* font-weight: 600; */
	background-color: rgb(240, 240, 240);
	margin-bottom: 0px;
}

.ylpa-overview-text-1 {
  font-weight: 900;
}

.ylpa-overview-text-2 {
  font-weight: 500;
}

.ylp_img {
	width: 100vw;
}

.ylp_img img {
	width: 100%;
	height: auto;
}

.ylp-registeration-heading {
  font-family: anton;
	padding: 0 20px 20px 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 2.5rem;
  font-style: italic;
}

.ylp-registeration-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  grid-gap: 30px;
  gap: 30px;
}

.ylp-registeration-text {
  font-weight: 600;
  color: rgb(0, 120, 225);
  font-size: 1.5rem;
}

.ylp-registeration-btn {
  padding: 5px;
	padding-right: 25px;
	padding-left: 25px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
  font-size: 1.2rem;
}

.ylp-registeration-note {
	padding: 40px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.ylp-registeration-note p {
	text-align: left;
	font-size: 18px;
  font-weight: 600;
}

.ylp-discount-container {
	display: flex;
	max-width: 100vw;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	grid-gap: 20px;
	gap: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #0076B8;
}

.ylp-discount-heading {
	color: #fff;
	font-size: 2rem;
	font-weight: 900;
}

.ylp-discount-heading_2 {
	color: #000000;
	font-size: 2rem;
	font-weight: 900;
}

.ylp-discount-p {
	color: #fff;
	font-size: 1.2rem;
	font-weight: 500;
	padding-left: 30px;
	padding-right: 30px;
}

.stat_banner_ylp {
	align-items: center;
	padding: 40px 0 40px 0;
}
.stat_banner_ylp img {
	width: 100%;
	height: auto;
}




@media (max-width: 768px) {
  .ylpa-overview-container {
		margin-left: 2px;
		margin-right: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 15px;
		text-align: justify;
		margin-bottom: 0px;
	}
  .ylp-registeration-text {
    padding: 0 20px 0 20px;
  }
	.stat_banner_ylp img {
		width: 100%;
		content: url("/static/media/YLP mob banner.9a74724d.webp");
	}
}

/* Ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.stat_banner_ylp img {
		width: 100%;
		content: url("/static/media/YLP mob banner.9a74724d.webp");
	}
}
.conference-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  grid-gap: 30px;
  gap: 30px;
}
.indiamun-logo {
  width: 8%;
}
.conference-heading {
  color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
}
.conference-p {
  font-size: 20px;
  margin: 0 30px 0 30px;
  font-weight: 700;
}

.imcc-hero-img-container {
  width: 100vw;
  margin-top: 50px;
  margin-bottom: 50px;
}

.imcc-hero-img-container img{
  width: 100%;
}

.imcc-invitation {
  width: 100vw;
  background-color: #252524;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.imcc-invitation p{
  text-align: center;
  /* margin: 20px 20px 0 20px; */
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.registrations-line {
  color: dodgerblue;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 35px;
  font-weight: 900;
	margin: 20px 0 0 0;
}

.indiamun-2021-recap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.indiamun-2021-recap img {
  width: 100%;
}

.imcc-registeration-heading {
  font-family: anton;
	padding: 0 10px 5px 10px;
  margin-top: 40px;
  margin-bottom: 0;
  color: rgb(0, 120, 225);
	font-weight: 400;
	font-size: 48px;
  font-style: italic;
}

.imcc-registeration-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  /* gap: 30px; */
}

.imcc-registeration-text {
  font-weight: 600;
  color: rgb(0, 120, 225);
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.agenda-img-container {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.agenda-img-container img {
  width: 50%;
  height: 50%;
}

.more-than-an-event {
  font-weight: 500;
  font-size: 1.5rem;
}

.imcc-img-container {
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imcc-img-container img{
  width: 60vw;
}

.imcc-registeration-btn {
  padding: 5px;
	padding-right: 25px;
	padding-left: 25px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
  margin-top: 20px;
  font-size: 1.2rem;
}

.welcome-letter-main-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100vw;
  overflow: hidden;
  justify-content: space-around;
}

.welcome-letter-sub-container {
  width: 45vw !important;
  /* max-width: 40vw; */
  padding: 12px 15px;
  text-align: left;
  overflow: hidden;
  border: 1.5px solid black;
  border-radius: 10px;
}

.welcome-letter-img {
  width: 10vw;
  border-radius: 50%;
  height: auto;
  overflow: hidden;
}

.welcome-letter-img img{
  width: 100%;
  object-fit: contain;
}

.welcome-letter-title {
  font-family: anton;
	padding-bottom: 10px;
}

.welcome-letter-p {
  text-align: justify;
  padding-top: 20px;
  font-weight: 500;
  font-size: 13px;
}

.welcome-letter-center {
  width: 100%;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center ;
}

.bold-span {
  font-weight: 900;
}

.high-head {
  color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
}

/* Mobile */
@media (max-width: 768px) {
  .indiamun-logo {
    width: 20%;
  }
  .high-head {
    font-size: 40px;
  }
  .imcc-invitation {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .imcc-invitation p{
    font-size: 18px;
  }

  .imcc-img-container img{
    width: 95vw;
  }

  .imcc-registeration-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .imcc-registeration-heading {
    font-size: 2rem;
  }

  .imcc-registeration-text {
    font-size: 1.3rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .imcc-registeration-btn {
    margin-top: 10px;
    font-size: 1.2rem;
  }

  .more-than-an-event {
    font-size: 1rem;
  }

  .welcome-letter-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .welcome-letter-sub-container {
    width: 90vw !important;
    margin-bottom: 20px;
  }

  .welcome-letter-img {
    width: 150px;
  }
  
  .welcome-letter-title {
    font-size: 20px;
  }

  .welcome-letter-p {
    font-size: 13px;
  }
}

/* Ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .high-head {
    font-size: 40px;
  }
  .imcc-invitation {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.after-payment-main {
  display: flex;
  flex-direction: column;
  width: 99vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.after-payment-thanks p{
  font-size: 25px;
  font-weight: 900;
}

.after-payment-main-btn{
  font-size: 20px;
  background-color: rgb(0, 120, 225);
  color: white;
  border: none;
  font-weight: 600;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 8px;
  margin-left: 3px;
}

@media (max-width: 768px) {
  .after-payment-thanks p{
    font-size: 15px;
  }
  .after-payment-main-btn {
		font-size: 13px;
		padding-right: 20px;
		padding-left: 20px;
		margin-bottom: 3px;
	}
}

.main-policy-container {
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.policy-title {
  /* margin-top: 40px;
  text-align: left;
  color: black;
  font-weight: 800; */
}

.policy-text {
  margin-top: 30px;
  text-align: left;
  color: black;
  font-weight: 600;
}

.policy-content {
  margin-top: 30px;
  text-align: left;
  color: black;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 90px;
}

.policy-sub-heading {
  font-size: 20px;
  text-align: left;
  color: black;
  font-weight: 800;
}
.contact-us-heading {
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact-us-main-container {
  margin-bottom: 50px;
  margin: 40px;
	padding: 40px;
	border: 0.6px dotted black;
}
/* .contact-us-form {

} */
.contact-us-form-items {
  display: flex;
	flex-wrap: wrap;
  width: 50%;
}

.contact-us-items-form {
  width: 90%;
	padding: 10px;
	font-family: "Quicksand", sans-serif;
	font-weight: 600;
	position: relative;
	text-align: left;
	align-items: start;
	justify-content: space-between ;
	display: flex;
	font-size: 20px;  
}

.contact-us-items-form input {
	border: 1px solid rgb(26, 26, 26);
	width: 85%;
  margin-left: 20px;
}

.contact-us-items-form textarea {
  border-radius: 5px;
	border: 1px solid rgb(26, 26, 26);
	width: 85%;
  margin-left: 20px;
}

.contact-us-button {
  width: 50%;
  display: flex;
  margin-top: 30px;
  /* align-items: center;
  justify-content: center; */
}

.contact-us-button button {
  padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
}

@media (max-width: 768px) {
  .contact-us-main-container {
    /* margin-bottom: 50px;
    margin-top: 50px; */
    margin: 40px 20px 50px 20px;
    padding: 10px;
    border: 0.6px dotted black;
  }
  .contact-us-form-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .contact-us-button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .contact-us-items-form {
    font-size: 14px;
  }
}

/* Ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .contact-us-form-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .contact-us-button {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
.login-main-container{
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  background-image: url("/static/media/login backdrop.b60c8bcb.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 700px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.register2-main-container{
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  background-image: url("/static/media/login backdrop.b60c8bcb.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 750px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.login-left-box{
  margin-top: 120px;
  width: 50%;
  display: flex;
  align-items: left;
  flex-direction: column;
  padding-left: 140px;
}

.login-heading{
  font-family: 'Poppins', sans-serif;
  text-align: left;
  font-size: 70px;
  color: white;
  font-weight: 700;
}

.login-img-container{
  width: 40%;
}

.login-img-container img{
  width: 100%;
}

.login-right-box{
  margin-right: 160px;
  background-color: white;
  padding: 60px;
  border-radius: 20px;
}

.forgot-right-box{
  margin-right: 160px;
  height: 350px;
  background-color: white;
  padding: 60px;
  border-radius: 20px;
}

.login-semi-1{
  padding-bottom: 20px;
}

.register-semi-1{
  padding-bottom: 60px;
}

.register2-semi-1{
  padding-bottom: 50px;
}

.login-subheading{
  font-family: 'Poppins', sans-serif;
  color: #1975d3;
  font-weight: 700;
  font-size: 20px;
}

.login-semi-2{
  padding-top: 20px;
  text-align: left;
}

.register-semi-2{
  padding-top: 50px;
  text-align: left;
}

.register2-semi-2{
  padding-top: 0px;
  text-align: left;
}

.register-semi-2 form{
  font-size: 18px;
}

.login-semi-2 form{
  font-size: 18px;
}

.login-error-message {
  font-family: 'Poppins', sans-serif;
  color: red;
  font-weight: 700;
  font-size: 18px;
  align-self: center;
}

.login-success-message {
  font-family: 'Poppins', sans-serif;
  color: green  ;
  font-weight: 700;
  font-size: 18px;
  align-self: center;
}

.login-email{
  margin-bottom: 10px;
}

.login-password{
  margin-top: 20px;
}

.login-main-button{
  margin-top: 30px;
  width: auto;
  align-self: center;
  text-decoration: none;
  background-color: #1975d3;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 5px 20px;
}

.login-semi-3{
  margin-top: 50px;
}

.login-forgot-password{
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .login-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .register2-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .login-left-box{
    margin-top: 0px;
    width: 80%;
    padding-left: 10px;
  }

  .login-heading{
    font-size: 30px;
  }  

  .login-img-container{
    width: 30%;
  }
  
  .login-img-container img{
    width: 100%;
  }

  .login-right-box{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 20px;
  }
  
  .forgot-right-box{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 20px;
    height: 300px;
  }

  .login-email input {
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .login-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .register2-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .login-left-box{
    margin-top: 0px;
    width: 80%;
    padding-left: 100px;
  }

  .login-heading{
    font-size: 50px;
  }  

  .login-img-container{
    width: 30%;
  }
  
  .login-img-container img{
    width: 100%;
  }

  .login-right-box{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    background-color: white;
    padding: 60px;
    border-radius: 20px;
  }

  .login-email input {
    width: 100%;
  }
}
.OnDemand-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.OnDemand-container h1{

}

.OnDemand-container p{
  
}

.wwu-main-heading {
	color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
	margin-top: 30px;
	margin-bottom: 5px;
}

.wwu-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.job-block {
  border: 1px solid black;
  border-radius: 10px;
  width: 70vw;
  /* margin: 30px 40px; */
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 20px 30px;
  text-align: left;
}

.job-title {
  padding-bottom: 20px;
}

.job-responsibilities {
  font-weight: 700;
}

.job-qualifications {
  font-weight: 700;
} 

.job-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.job-button {
  padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	border: none;
	font-weight: 700;
	border-radius: 10px;
	background-color: rgb(0, 120, 225);
	color: #fff;
	margin-top: 10px;
	margin-bottom: 10px;
}

@media (max-width: 768px){
  .job-block {
    width: 90vw;
    margin-bottom: 40px;
    padding: 20px 20px;
    text-align: left;
  }
}
.Winners-2024-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  grid-gap: 30px;
  gap: 30px;
}

.winner-phone {
  display: none;
}

.winner-2024-heading {
  color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 48px;
}

.winner-2024-heading1 {
  color: rgb(0, 120, 225);
	font-family: anton;
	font-weight: 400;
	font-size: 56px;
}

.winner-2024-heading2 {
  color: black;
  text-align: left;
  padding-left: 300px;
	font-family: anton;
  padding-top: 40px;
	font-weight: 400;
	font-size: 44px;
}

.winner-2024-heading3 {
  color: black;
  text-align: left;
  padding-left: 300px;
	font-family: anton;
  padding-top: 20px;
  padding-bottom: 20px;
	font-weight: 400;
	font-size: 44px;
}

.yt_video_2024_winner {
	aspect-ratio: 16/9;
	width: 900px;
}

.images-2024 {
  width: 100vw;
  overflow: hidden;
}

.img-2024 {
  width: 70%;
}

@media (max-width: 768px) {
  .Winners-2024-hero{
    margin-top: 20px;
    margin-bottom: 20px;
    grid-gap: 10px;
    gap: 10px;
  }

  .winner-phone{
    display: block;
  }

  .winner-2024-heading1 {
    font-size: 34px;
  }

  .winner-2024-heading2 {
    padding-left: 25px;
    font-size: 24px;
    padding-top: 10px;
  }

  .winner-2024-heading3 {
    padding-left: 25px;
    font-size: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .winner-pc {
    display: none;
  }

  .winner-2024-heading{
    font-size: 30px;
  }

  .yt_video_2024_winner {
    aspect-ratio: 16/9;
    width: 300px !important;
  }

  .img-2024 {
    width: 100%;
  }
}
