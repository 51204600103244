.after-payment-main {
  display: flex;
  flex-direction: column;
  width: 99vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.after-payment-thanks p{
  font-size: 25px;
  font-weight: 900;
}

.after-payment-main-btn{
  font-size: 20px;
  background-color: rgb(0, 120, 225);
  color: white;
  border: none;
  font-weight: 600;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 8px;
  margin-left: 3px;
}

@media (max-width: 768px) {
  .after-payment-thanks p{
    font-size: 15px;
  }
  .after-payment-main-btn {
		font-size: 13px;
		padding-right: 20px;
		padding-left: 20px;
		margin-bottom: 3px;
	}
}
