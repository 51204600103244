@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.login-main-container{
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  background-image: url('./images/INDIAMUN/login\ backdrop.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 700px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.register2-main-container{
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  background-image: url('./images/INDIAMUN/login\ backdrop.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 750px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.login-left-box{
  margin-top: 120px;
  width: 50%;
  display: flex;
  align-items: left;
  flex-direction: column;
  padding-left: 140px;
}

.login-heading{
  font-family: 'Poppins', sans-serif;
  text-align: left;
  font-size: 70px;
  color: white;
  font-weight: 700;
}

.login-img-container{
  width: 40%;
}

.login-img-container img{
  width: 100%;
}

.login-right-box{
  margin-right: 160px;
  background-color: white;
  padding: 60px;
  border-radius: 20px;
}

.forgot-right-box{
  margin-right: 160px;
  height: 350px;
  background-color: white;
  padding: 60px;
  border-radius: 20px;
}

.login-semi-1{
  padding-bottom: 20px;
}

.register-semi-1{
  padding-bottom: 60px;
}

.register2-semi-1{
  padding-bottom: 50px;
}

.login-subheading{
  font-family: 'Poppins', sans-serif;
  color: #1975d3;
  font-weight: 700;
  font-size: 20px;
}

.login-semi-2{
  padding-top: 20px;
  text-align: left;
}

.register-semi-2{
  padding-top: 50px;
  text-align: left;
}

.register2-semi-2{
  padding-top: 0px;
  text-align: left;
}

.register-semi-2 form{
  font-size: 18px;
}

.login-semi-2 form{
  font-size: 18px;
}

.login-error-message {
  font-family: 'Poppins', sans-serif;
  color: red;
  font-weight: 700;
  font-size: 18px;
  align-self: center;
}

.login-success-message {
  font-family: 'Poppins', sans-serif;
  color: green  ;
  font-weight: 700;
  font-size: 18px;
  align-self: center;
}

.login-email{
  margin-bottom: 10px;
}

.login-password{
  margin-top: 20px;
}

.login-main-button{
  margin-top: 30px;
  width: auto;
  align-self: center;
  text-decoration: none;
  background-color: #1975d3;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 5px 20px;
}

.login-semi-3{
  margin-top: 50px;
}

.login-forgot-password{
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .login-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .register2-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .login-left-box{
    margin-top: 0px;
    width: 80%;
    padding-left: 10px;
  }

  .login-heading{
    font-size: 30px;
  }  

  .login-img-container{
    width: 30%;
  }
  
  .login-img-container img{
    width: 100%;
  }

  .login-right-box{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 20px;
  }
  
  .forgot-right-box{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 20px;
    height: 300px;
  }

  .login-email input {
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .login-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .register2-main-container{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .login-left-box{
    margin-top: 0px;
    width: 80%;
    padding-left: 100px;
  }

  .login-heading{
    font-size: 50px;
  }  

  .login-img-container{
    width: 30%;
  }
  
  .login-img-container img{
    width: 100%;
  }

  .login-right-box{
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    background-color: white;
    padding: 60px;
    border-radius: 20px;
  }

  .login-email input {
    width: 100%;
  }
}