.iyfa-head{
    font-size: 40px;
    font-family: anton;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 0px;
}
.iya-next{
    font-size: 22px;
    text-align: left;
    padding-left: 40px;
    margin-top: -10px;
}
.iyfa-top{
    text-align: left;
    padding-right: 40px;
    padding-left: 40px;
    font-size: 25px;
    color: rgb(8, 139, 210);
    text-decoration: underline;
    font-weight: 600;
}
.am-iyfa{
    display: flex;
    align-items: flex-start;
    padding-left: 40px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.am-iyfa p{
    width: 95%;
    padding-bottom: 30px;
    border-bottom: 1.5px solid black;
}
.sub-head{
    font-size: 25px;
    font-weight: 700;
    text-align: left;
    padding-left: 40px;
    font-weight: 700;
}
.am-cont{
    padding-left: 40px;
    padding-right: 40px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.what-eco{
    padding-left: 40px;
    text-align: left;
    font-size: 17px;
    color: rgb(11, 158, 237);
    font-weight: 600;
}
.mod-vid{
    margin-top: -15px;
    padding-left: 40px;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
}
.mod-vid span{
    font-weight: 600;
}
.mod-vid a{
    text-decoration: none;
    color: black;
}
.btn-div{
    padding-left: 10px;
    text-align: left;
    width: 95%;
    padding-bottom: 20px;
    margin: 0 auto;
    border-bottom: 1.5px solid black;
  
}
.btn-div button{
    color: white;
    background-color: rgb(66, 128, 222);
    border: none;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 600;
    margin-right: 3px;
}
.sub-2{
    padding-top: 20px;
}
.t-main{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .t-note{
    font-weight: 600;
    margin: 0;
    text-align: left;
    font-style: italic;
  }
  .b-bottom{
    padding-bottom: 13px;
  }
  .rzp{
    display: flex;
    justify-content:flex-start;
    /* align-items: center; */
    width: 95%;
    margin: 0 auto;
    border-top: 1.5px solid black;
    padding-top: 37px;
    /* margin-bottom: 20px; */
  }
  .rzp button{
    font-size: 45px;
    background-color: rgb(184, 214, 56);
    border: none;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    font-family: anton;
  }
  .rzp img{
    height: 40px;
    width: auto;
    margin-left: 12px;
    position: relative;
    align-self:flex-end;
  }
  .main-mod{
    padding: 40px;
    background-color: rgb(242, 248, 253);
}
.main-mod p{
    text-align: left;
    font-weight: 600;
}

.school-form{
  margin: 40px;
  padding: 40px;
  border: 0.5px solid black;
}
.school-form-head{
  font-size: 30px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}
.school-form-items{
  display: flex;
  flex-wrap: wrap;
}
.school-items{
  display: flex;
  width: 100%;
  padding: 10px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  justify-content: space-between;
  position: relative;
}
.school-items input{
  position: absolute;
  right: 40px;
  width: 70%;
}
.qr-div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rpqr{
    height: 400px;
    width: auto;
    padding-bottom: 20px;
}
.sub-btn button{
    margin-top: 20px;
    color: white;
    background-color: rgb(66, 128, 222);
    border: none;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 600;
    font-size: 22px;
}
.bold{
    color: rgb(27, 27, 182);
}
.small{
    font-size: 10px;
    padding-left: 10px;
}
.sub-btn-end button{
    background-color: rgb(16, 216, 16);
    border: none;
    border-radius: 7px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 600;
    font-size: 22px;
    margin-top: 20px;
    color: white;
}
.hyperlinkhere{
    color: blue;

}
.qr-div1{
    display: none;
}
@media (max-width: 768px){
    .iyfa-head{
        font-size: 35px;
    }
    .iya-next{
        font-size: 18px;
    }
    .iyfa-top{
        font-size: 22px;
    }
    .sub-head{
        font-size: 22px;
    }
    .mod-vid{
        font-size: 15px;
    }
    .btn-div{
        padding-left: 30px;
    }
    .am-cont{
        text-align: justify;
    }
    .rzp{
        padding-top: 20px;
        padding-left: 20px;
    }
    .rzp button{
        font-size: 18px;
    }
    .rzp img{
        height: 15px;
    }
    .school-items{
        font-size: 10px;
        padding: 10px;
      }
      .school-form{
        margin: 0px;
        padding: 20px;
      }
      .school-items input{
        right: 0px;
        width: 50%;
      }
      .qr-div{
          display: none;
      }
      .qr-div1{
          display: flex;
          align-items: center;
          justify-content: center;
          
      }
      .small{
        padding-left: 6px;
      }
      .main-mod p{
          text-align: justify;
      }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .school-items{
      font-size: 13px;
      padding: 10px;
    }
    .school-items input{
      right: 0px;
      width: 100%;
    }
  }